<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep"
                      showControlType>
        <template v-slot:formMap>
            <FormPositions v-model="form.points" :field="form.field" @reloadFormData="getFormData" objectBeingChanged="punkt"/>
        </template>
        <template v-slot:formObservations>
            <FormMPDObservations v-model:allPositions="form.points" v-model:step="step"
                                 ref="observationsView" :field="form.field.shape" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMPDObservations from "./components/FormMPDObservations.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import {getForm, getList as getDict} from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "FormMPDView",

        components: {
            FormMPDObservations,
            FormViewTemplate,
            FormPositions,
        },

        data() {
            return {
                programCode: "MPD",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
                ValidateUtils,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        console.log(this.form);
                        ValidateUtils.flattenData(this.form.points, "point");
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loaded = false;
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "points");
                if (!this.form.noFormControl) {
                    for (let i = 0; i < this.form.points.length; i += 1) {
                        ValidateUtils.formatControlDateStartTimeAndEndTime(this.form.points[i]);
                        request.points.push(this.form.points[i]);
                    }
                }
                return request;
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = this.validateFirstStep();
                    for (let i = 0; i < this.form.points.length; i += 1) {
                        let pointIsCorrect = true;
                        const point = this.form.points[i];
                        if (point.notAudited == null || point.notAudited === false) {
                            pointIsCorrect = ValidateUtils.validateNotEmpty(point, ["controlDate", "startTime", "endTime"]);
                            pointIsCorrect = ValidateUtils.validateIsDateInRange(point.controlDate, this.form.control.controlStart, this.form.control.controlEnd) && pointIsCorrect;
                            if (DateUtils.isTimeEarlier(point.endTime, point.startTime)) pointIsCorrect = false;
                            pointIsCorrect = ValidateUtils.validateBirds(point.birds, point.noBirds, ["species", "districts", "count"]) && pointIsCorrect;
                        }
                        if (point.error) delete point.error; // remove previous checks
                        if (!pointIsCorrect) {
                            point.error = true;
                            isCorrect = false;
                        }
                    }
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.points, "Punkt");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.points, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
