<template>
    <div :class="`header-component-container flex justify-content-between h${hierarchy}`">
        <h2 :class="uppercase ? 'header-component-text uppercase' : 'header-component-text'">
            {{ headerText }}
        </h2>
        <div class="flex gap-3 flex-wrap justify-content-end">
            <CustomButton v-for="(button,index) in buttons" v-show="button.show" :key="button.label"
                          :label="button.label" :icon="button.icon ? button.icon : null"
                          :bgColor="button.bgColor ? button.bgColor : defaultBgColor"
                          :color="button.labelColor ? button.labelColor : defaultLabelColor"
                          :borderColor="button.borderColor ? button.borderColor : null"
                          :disabled="button.disabled"
                          @click="$emit('clickButton',index)" />
        </div>
    </div>
</template>

<script>
    import CustomButton from "./form/CustomButton";

    export default {
        name: "HeaderComponent",

        components: {
            CustomButton,
        },

        props: {
            headerText: {
                type: String,
            },
            uppercase: {
                type: Boolean,
                default: false,
            },
            buttons: {
                type: Array,
                default: () => [],
            },
            hierarchy: {
                type: Number,
                default: 4,
            },
        },

        emits: ["clickButton"],

        data() {
            return {
                defaultLabelColor: "var(--surface-a)",
                defaultBgColor: "var(--secondary-color)",
            };
        },
    };
</script>

<style lang="scss">
    @import "../assets/theme/mytheme/variables";
    .header-component {
        &-container {
            padding: 12px;
            border-radius: 4px;
            height: 44px;
            @media screen and (max-width: 576px) {
                height: auto;
            }
            display: flex;
            align-items: center;
        }
        &-text {
            margin: 0;
            font-size: 1.07rem;
            font-weight: 600;
            line-height: 15px;
            &.text-uppercase {
                text-transform: uppercase;
            }
        }
    }
    .h1 {
        background-color: var(--secondary-color);
    }
    .h2 {
        background-color: var(--secondary-color-light);
        color: var(--text-color-black);
        font-weight: normal;
    }
    .h3 {
        background-color: var(--surface-d);
        color: var(--text-color-black);
        font-weight: normal;
    }
    .h4 {
        background-color: var(--primary-color);
    }
</style>
