<!-- eslint-disable -->
<template>
    <div class="overflow-x-auto">
        <DataTable :value="birds" v-model:editingRows="editingRows" editMode="row" dataKey="ordinal"
                   @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'"
                   class="navigate-table" @click="arrowsNavigateTable" :style="`min-width: ${minWidth}px;`">
            <template #header>
                <AddBirds v-model="birds" :columns="columns" :error="error" :showSpeciesButton="showSpeciesButton"
                          :style="!showSpeciesButton ? 'margin-left: 4.1666%' : ''"/>
            </template>
            <Column v-if="lp" header="Lp." field="ordinal" style="width: 4.1666%;" />
            <Column v-for="(c,index) in columns" :key="index" v-show="c.visible"
                    :header="c.header" :field="c.field" :class="`w-${c.columnWidth}`">
                <template #body="slotProps">
                    <div v-if="c.bodyTooltip && !c.offTableTooltip"
                         :class="c.bodyClass(slotProps.data)"
                            v-tooltip.top="c.bodyTooltipContent(slotProps.data)">
                        {{ c.bodyContent(slotProps.data) }}
                    </div>
                    <div v-else :class="c.bodyClass(slotProps.data)">
                        {{ c.bodyContent(slotProps.data) }}
                    </div>
                </template>
                <template #editor="{data,field}">
                    <CustomAutocompleteBirdSpecies v-if="c.editorType === 'autocompleteBirdSpecies'" v-model="data[field]" :emptyValue="c.editorEmptyValue" :name="c.field" :searchParam="c.searchParam" @update:modelValue="completeSpecies(data, c.field)" />
                    <CustomAutocompleteTreeSpecies v-if="c.editorType === 'autocompleteTreeSpecies'" v-model="data[field]" :emptyValue="c.editorEmptyValue" :name="c.field" :searchParam="c.searchParam" />
                    <CustomInputText v-else-if="c.editorType === 'inputText'" v-model="data[field]" :name="c.field" />
                    <CustomInputNumber v-else-if="c.editorType === 'inputNumber'" v-model="data[field]" :name="c.field" :notZero="c.notZero" :min="c.min ? c.min : 0" />
                    <CustomSelectOne v-else-if="c.editorType === 'selectOne'" v-model="data[field]" :items="c.field === 'species' && !otherBirds ? dicts.programBirds : (dicts[c.editorItems] ? dicts[c.editorItems] : [])" :itemLabel="c.field === 'nestingCriteria' ? getDictLabelWithCodePrefix : c.editorItemLabel"
                    :itemValue="c.editorItemValue" :emptyValue="c.editorEmptyValue" :name="c.field"
                    :tooltip="c.bodyTooltip" />
                    <CustomMultiSelect v-else-if="c.editorType === 'multiSelect'" v-model="data[field]" :items="dicts[c.editorItems] ? dicts[c.editorItems] : []" :itemLabel="c.editorItemLabel" :itemValue="c.editorItemValue" :emptyValue="c.editorEmptyValue" :name="c.field" />
                    <CustomCheckbox v-else-if="c.editorType === 'checkbox'" v-model="data[field]" :name="c.field" style="margin-bottom: 0 !important;" />
                </template>
            </Column>
            <Column :class="hiddenColumnWidth > 0 ? `class-${hiddenColumnWidth}`: ''"/>
            <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
            <Column v-if="editing" style="width: 4.1666%; text-align: center;">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                    @click="deleteBirdObservation(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    /* eslint-disable */
    import _ from "lodash";
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Tooltip from "primevue/tooltip";
    import CustomInputText from "@/components/form/CustomInputText.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomAutocompleteBirdSpecies from "@/components/form/CustomAutocompleteBirdSpecies.vue";
    import CustomAutocompleteTreeSpecies from "@/components/form/CustomAutocompleteTreeSpecies.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import AddBirds from "./AddBirds.vue";
    import TableUtils from "@/utils/TableUtils";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "BirdsTable",

        components: {
            DataTable,
            Column,
            Tooltip,
            CustomInputText,
            CustomInputNumber,
            CustomMultiSelect,
            CustomSelectOne,
            CustomCheckbox,
            CustomAutocompleteBirdSpecies,
            CustomAutocompleteTreeSpecies,
            CustomButton,
            AddBirds,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
            columns: {
                type: Array,
                required: true,
            },
            error: {
                type: Boolean,
                default: false,
            },
            lp: {
                type: Boolean,
                default: true,
            },
            otherBirds: {
                type: Boolean,
                default: false,
            },
            minWidth: {
                type: Number,
                default: 1440,
            },
        },

        inject: ["editing", "dicts", "programCode"],

        emits: ["update:modelValue"],

        data() {
            return {
                editingRows: ref([]),
                hiddenColumnWidth: null,
                showSpeciesButton: false,
            };
        },

        beforeMount() {
            if (this.programCode === "MRD") {
                this.dicts.programBirds = this.dicts.programBirds.filter((b) => b.info?.length > 0);
            }
            let i = 2;
            const fieldArr = [];
            this.columns.forEach((c) => {
                i += c.columnWidth
                fieldArr.push(c.field);
            });
            this.showSpeciesButton = fieldArr.includes("species");
            this.hiddenColumnWidth = 12 - i;
        },

        methods: {
            onRowEditSave(event) {
                const {newData, index} = event;
                this.birds[index] = newData;
            },
            deleteBirdObservation(item) {
                const index = this.birds.indexOf(item);
                this.birds.splice(index, 1);
            },
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 40) {
                    return _.truncate(text, {
                        length: 40,
                        separator: " ",
                    });
                }
                return text;
            },
            completeSpecies(ctx, key) {
                if (key === "species" && ctx && ctx.species?.code) ctx.species2 = ctx.species;
                if (key === "species2" && ctx && ctx.species2?.code) ctx.species = ctx.species2;
            },
        },

        computed: {
            birds: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            birds: [{
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            }, {
                handler(value) { TableUtils.addOrdinal(value); },
                immediate: true,
            }],
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/variables";

.true-style, .false-style {
    padding: 2px 8px;
    width: fit-content;
    border-radius: 4px;
}
.true-style {
    color: var(--secondary-color);
    background-color: $secondaryLightColor;
}
.false-style {
    color: var(--red);
    background-color: $colorLightRed;
}
</style>
