<template>
    <div class="p-3">
        <h2>Wysłanie e-maila</h2>
        <div class="flex gap-5 flex-wrap" v-if="loadPrograms && loadUsers">
            Według roli:
            <CustomMultiSelect name="role.role" label="Role" :items="roles" itemValue="key" v-model="search.role.roles"
                               class="w-3 mb-0" :filter="false" />
            <CustomMultiSelect name="role.program" label="W programach" :items="programs" itemValue="value"
                               v-model="search.role.programs" class="w-3 mb-0" />
            <CustomMultiSelect name="user.roles" label="Wybierz użytkowników" :items="userRoles" itemLabel="name"
                               itemValue="id" v-model="selectedUsers.role" class="w-3 mb-0"
                               virtual-scrolling @update:model-value="updateRoleUser" />
            Według aktywnych programów:
            <CustomMultiSelect name="form.role" label="Role" :items="rolesInPrograms" itemValue="key"
                               v-model="search.form.roles" class="w-3 mb-0" :filter="false" />
            <CustomMultiSelect name="form.program" label="W programach" :items="programs" itemValue="value"
                               v-model="search.form.programs" class="w-3 mb-0" />
            <CustomMultiSelect name="form.states" label="Dla formularzy o statusie" :items="formStates"
                               itemValue="id" v-model="search.form.formState" class="w-3 mb-0" />
            <CustomMultiSelect name="user.roles" label="Wybierz użytkowników" :items="userForms" itemLabel="name"
                               itemValue="id" v-model="selectedUsers.program" class="w-3 mb-0"
                               virtual-scrolling @update:model-value="updateProgramUser" />
            Wszyscy użytkownicy:
            <CustomMultiSelect name="inni" label="Do innych użytkowników" :items="users" itemLabel="name"
                               itemValue="id" v-model="selectedUsers.all" class="w-3 mb-0"
                               virtual-scrolling @update:model-value="updateAllUser" />
        </div>
        <div class="flex mt-5 gap-5">
            <div class="flex-1">
                <CustomInputText v-model="emailData.subject" label="Tytuł" name="tytuł" rules="required"/>
                <Editor editorStyle="height: 320px" v-model="emailData.message" class="flex-1">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <!-- eslint-disable-next-line -->
                            <select class="ql-header">
                                <option value="1">Heading</option>
                                <option value="2">Subheading</option>
                                <option value="0">Normal</option>
                            </select>
                            <button type="button" class="ql-bold"></button>
                            <button type="button" class="ql-italic"></button>
                            <button type="button" class="ql-underline"></button>
                        </span>
                        <span class="ql-formats">
                            <button type="button" class="ql-list" value="ordered"></button>
                            <button type="button" class="ql-list" value="bullet"></button>
                        </span>
                        <span class="ql-formats">
                            <button type="button" class="ql-link"></button>
                        </span>
                    </template>
                </Editor>
                <div class="flex gap-3">
                    <CustomButton label="Wyślij" icon="pi pi-send" class="mt-3"
                                  bgColor="var(--secondary-color)" color="var(--surface-a)"
                                  @click="submitForm" />
                    <CustomButton label="Odrzuć" class="mt-3"
                                  @click="clearEmailData"/>
                </div>
            </div>
            <div class="flex-1">
                <h2 class="mt-1">Dostępne znaczniki</h2>
                <ul v-if="loadTags" class="list-none p-0 m-0" >
                    <li v-for="tag in tags" :key="JSON.stringify(tag)" class="mb-2">
                        {{ tag.mark }} - {{ tag.explanation }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import Editor from "primevue/editor";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomInputText from "@/components/form/CustomInputText";
    import {
        getListOfFormStates,
        getListOfPrograms, getUserRoles,
        listUsers, searchRecipientsByProgram, searchRecipientsByRole,
    } from "@/swagger/vue-api-client";
    import CustomButton from "@/components/form/CustomButton.vue";
    import {ControlState} from "@/utils/ControlState";

    export default {
        name: "SendEmail",
        components: {
            CustomInputText,
            CustomMultiSelect,
            Editor,
            CustomButton,
        },
        data() {
            return {
                loadTags: false,
                emailData: {
                    recipientIds: [],
                    subject: "",
                    message: "",
                },
                search: {
                    role: {
                        programs: [],
                        roles: [],
                    },
                    form: {
                        roles: [],
                        programs: [],
                        formState: [],
                    },
                },
                programs: [],
                loadPrograms: false,
                loadUsers: false,
                tags: [],
                selectedFiltered: [],
                selectedOther: [],
                isCorrect: false,
                roles: [],
                userRoles: [],
                userForms: [],
                users: [],
                rolesInPrograms: [],
                formStates: [],
                selectedUsers: {
                    all: [],
                    role: [],
                    program: [],
                    internalValue: new Set(),
                },
            };
        },

        beforeMount() {
            this.getPrograms();
            this.getUsersList();
            this.getTags();
            this.getRoles();
            this.getFormStates();
        },
        watch: {
            // emailData: {
            //     handler() { this.validate(); },
            //     deep: true,
            // },
            "search.role": {
                handler(value) {
                    searchRecipientsByRole({body: value})
                        .then((response) => {
                            this.userRoles = response.data;
                        });
                },
                deep: true,
            },
            "search.form": {
                handler(value) {
                    const body = {...value};
                    if (body.programs.length === 0) return;
                    body.formState = body.formState.map((state) => {
                        if (state === "NOT_CREATED") return null;
                        return state;
                    });
                    searchRecipientsByProgram({ body })
                        .then((response) => {
                            this.userForms = response.data;
                        });
                },
                deep: true,
            },
        },
        methods: {
            getPrograms() {
                getListOfPrograms()
                    .then((response) => {
                        this.programs = response.data;

                        // In order to use search, is necessary to change data structur
                        for (let i = 0; i < this.programs.length; i += 1) {
                            this.programs[i] = {label: this.programs[i], value: this.programs[i]};
                        }

                        this.loadPrograms = true;
                    });
            },
            getRoles() {
                getUserRoles()
                    .then((response) => {
                        this.roles = response.data;
                        this.rolesInPrograms = this.roles.filter((role) => role.key === "OBSERVER"
                            || role.key === "REGIONAL_COORDINATOR" || role.key === "NATIONAL_COORDINATOR");
                    });
            },
            getFormStates() {
                getListOfFormStates()
                    .then((response) => {
                        this.formStates = response.data
                            .map((state) => ({id: state, label: ControlState.getStateLabel(state)}));
                        this.formStates.push({id: "NOT_CREATED", label: ControlState.getStateLabel("NOT_CREATED")});
                    });
            },
            getTags() {
                // getTagsList()
                //     .then((response) => {
                //         this.tags = response.data;
                //         this.loadTags = true;
                //     });

                // only for mockup
                this.tags = [
                    {mark: "{$ userName}", explanation: "imię i nazwisko adresata"},
                    {mark: "{$ program}", explanation: "nazwa programu"},
                    {mark: "{$ date}", explanation: "dzisiejsza data"},
                ];
                this.loadTags = true;
            },
            getUsersList() {
                listUsers()
                    .then((response) => {
                        this.users = response.data;
                        this.loadUsers = true;
                    });
            },
            // validate() {
            //     this.isCorrect = false;
            //     Object.keys(this.emailData.recipients).forEach((key) => {
            //         if (this.emailData.recipients[key].length > 0) this.isCorrect = true;
            //     });
            //     if (this.isCorrect) {
            //         if (this.emailData.subject.trim() === "" || this.emailData.message.trim() === "") {
            //             this.isCorrect = false;
            //         }
            //     }
            //     return this.isCorrect;
            // },
            clearEmailData() {
                Object.keys(this.emailData.recipients).forEach((key) => {
                    this.emailData.recipients[key] = [];
                });
                this.emailData.subject = "";
                this.emailData.message = "";
            },
            submitForm() {
                // if (this.validate()) {
                const emailRequest = this.emailData;
                console.log(emailRequest);
                // sendEmail({
                //     body: emailRequest,
                // }).then((response) => {
                // this.clearEmailData();
                // }
                // });
                // }
            },
            updateAllUser(newValue) {
                this.listToSet(newValue, this.selectedUsers.internalValue);
                this.setToList(this.selectedUsers.role, this.selectedUsers.internalValue, this.userRoles);
                this.setToList(this.selectedUsers.program, this.selectedUsers.internalValue, this.userForms);
            },
            updateRoleUser(newValue) {
                this.listToSet(newValue, this.selectedUsers.internalValue, this.userRoles);
                this.setToList(this.selectedUsers.all, this.selectedUsers.internalValue, this.users);
                this.setToList(this.selectedUsers.program, this.selectedUsers.internalValue, this.userForms);
            },
            updateProgramUser(newValue) {
                this.listToSet(newValue, this.selectedUsers.internalValue, this.userForms);
                this.setToList(this.selectedUsers.role, this.selectedUsers.internalValue, this.userRoles);
                this.setToList(this.selectedUsers.all, this.selectedUsers.internalValue, this.users);
            },
            listToSet(selected, set, all) {
                if (all === undefined) {
                    set.clear();
                    selected.forEach((u) => set.add(u));
                    return;
                }
                const selectedSet = new Set(selected);
                all.forEach(({ id }) => {
                    if (selectedSet.has(id)) {
                        set.add(id);
                    } else {
                        set.delete(id);
                    }
                });
            },
            setToList(selected, set, all) {
                const selectedSet = new Set(selected);
                all.forEach(({ id }) => {
                    if (set.has(id) && !selectedSet.has(id)) {
                        selectedSet.add(id);
                    } else if (!set.has(id) && selectedSet.has(id)) {
                        selectedSet.delete(id);
                    }
                });
                selected.splice(0);
                selectedSet.forEach((u) => selected.push(u));
            },
        },
    };

</script>

<style scoped lang="scss">
</style>
