<template>
    <div v-show="errorMessage" class="absolute error-msg">
        <span>{{ errorMessage }}</span>
    </div>
</template>

<script>
    export default {
        name: "CustomValidationMessage",

        props: {
            errorMessage: {
                type: String,
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../../assets/theme/mytheme/variables";
    .error-msg {
        background-color: $color-red;
        border-radius: 8px;
        top: calc(100% + 4px);
        color: var(--surface-a);
        display: flex;
        padding: 4px 6px;
        z-index: 99;
        font-size: 12px;

        &::after {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid $color-red;
            content: "";
            left: 20px;
            position: absolute;
            bottom: 100%;
        }
    }
</style>
