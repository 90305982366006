<template>
    <ImportErrorsDialog v-model="showImportErrorsDialog" :errors="errors" />
    <Dialog header="Importuj kontrole" v-model:visible="visible" class="import-controls-dialog dialog-file-upload"
            :closable="false" modal :draggable="false">
        <template #header>
            <CustomFileUpload @importFile="importFile" :multiple="false" accept=".xlsx, .xls"
                              class="import-controls-file-upload" />
        </template>
        <div class="dialog-file-upload-buttons import-controls-dialog-buttons w-100 h-100">
            <CustomButton label="Importuj kontrole" bgColor="transparent" @click="importControls"
                          :disabled="waitForServer" />
            <CustomButton label="Anuluj" class="flex justify-content-center" @click="visible = false"
                          bgColor="transparent" />
        </div>
    </Dialog>
</template>

<script>
    import axios from "axios";
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomFileUpload from "@/components/CustomFileUpload.vue";
    import ImportErrorsDialog from "@/components/formSharedComponents/dialogs/ImportErrorsDialog.vue";

    export default {
        name: "ImportControlsDialog",

        components: {
            CustomFileUpload,
            CustomButton,
            Dialog,
            ImportErrorsDialog,
        },

        props: {
            modelValue: null,
        },

        inject: ["programCode"],

        data() {
            return {
                importControlsFile: null,
                waitForServer: false,
                showImportErrorsDialog: false,
                errors: null,
            };
        },

        methods: {
            importFile(value) {
                this.importControlsFile = value;
            },

            importControls() {
                this.waitForServer = true;
                // importControlsFromExcel({programEditionId: this.$route.params.id, file: this.importControlsFile})
                //     .then((response) => {
                //         console.log(response);
                //     });
                axios.post(
                    `${process.env.VUE_APP_API_URL}/import-form/${this.$route.params.id}`,
                    this.importControlsFile,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        this.waitForServer = false;
                        this.visible = false;
                        this.$toast.add({
                            severity: "success",
                            summary: "Zaimportowano pomyślnie",
                            life: 3000,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.errors = err.response.data;
                        this.waitForServer = false;
                        this.showImportErrorsDialog = true;
                        this.visible = false;
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd: " + err.response.data,
                            life: 3000,
                        });
                    });
            },
        },

        computed: {
            visible: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="scss">
.import-controls-dialog.p-dialog.dialog-file-upload {
    .p-dialog-header {
        width: 500px;
        @media screen and (max-width: 500px) {
            width: 100vw;
        }
        height: 200px;
        justify-content: center;
        padding: 0;
    }
}

.import-controls-file-upload {
    .file-upload-dropzone {
        width: 500px;
        height: 200px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        .file-upload-label {
            text-align: center;
            line-height: 20px;
            font-size: 1rem;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
        .file-preview-container {
            height: 100%;
            padding: 1rem;
            flex-wrap: wrap;
            column-gap: 10px
        }
    }
}
</style>
