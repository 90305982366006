<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position" :controls="controls">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlMainData="slotProps">
            <ControlTypeAndDates v-model="slotProps.control"/>
            <CustomSelectOne label="Metoda obserwacji" name="controlMethod" v-model="slotProps.control.controlMethod"
                             class="w-4 pr-1" :items="dicts.controlMethod" required
                             :showErrors="showErrorMessages" :disabled="!editing"
                             style="min-width: 280px;"/>
        </template>
        <template v-slot:separateBirdsData>
            <HeaderComponent headerText="Mewa czarnogłowa" :hierarchy="3" uppercase class="mb-4"/>
            <FormBirdsMMC v-model:birds="position.birds" v-model:noBirds="position.noBirds"
                          :disabledNC1="disabledNC1" :disabledNC2="disabledNC2" />
            <HeaderComponent headerText="Inne gatunki obecne w kolonii" :hierarchy="3" uppercase class="mb-4"/>
            <FormOtherBirdsMMC v-model:birds="position.others" v-model:noBirds="position.noOthers"
                               :controlMethod="position.controlMethod"/>
        </template>
        <template v-slot:habitatData>
            <HabitatDataMMC :habitatData="position.habitatData" v-model:habitatDataToChange="position.habitatDataToChange"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import FormBirdsMMC from "@/views/formMMC/components/FormBirdsMMC.vue";
    import FormOtherBirdsMMC from "@/views/formMMC/components/FormOtherBirdsMMC.vue";
    import HabitatDataMMC from "@/views/formMMC/components/HabitatDataMMC.vue";

    export default {
        name: "FormMMCObservations",

        components: {
            HabitatDataMMC,
            FormOtherBirdsMMC,
            FormBirdsMMC,
            HeaderComponent,
            CustomSelectOne,
            FormObservationsTemplate,
            ObservationsSelectPosition,
            ControlTypeAndDates,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                positions: [],
                activeIndex: 0,
                index: 0,
                position: _.cloneDeep(EmptyStructures.getEmptyMMCPosition()),
                control: _.cloneDeep(EmptyStructures.getEmptyMMCControl()),
                controls: [],
                disabledNC1: false,
                disabledNC2: false,
            };
        },

        emits: ["update:step", "update:form"],

        inject: ["showErrorMessages", "editing", "dicts"],

        beforeMount() {
            this.positions = this.internalForm.positions.filter((p) => (p.notAudited === null || !p.notAudited));
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            position: {
                handler(value) {
                    this.controls[0] = value.control1;
                    this.controls[1] = value.control2;
                    this.controls.forEach((c) => {
                        if (c.controlMethod == null) {
                            c.controlMethod = {key: "VISIT2", code: "Visit", label: "bezpośrednia wizyta w kolonii"};
                        }
                    });
                    if (value.control1.noControl) {
                        this.disabledNC1 = true;
                        this.dicts.controlType[0].disabled = true;
                    } else {
                        this.disabledNC1 = false;
                        this.dicts.controlType[0].disabled = false;
                    }
                    if (value.control2.noControl) {
                        this.disabledNC2 = true;
                        this.dicts.controlType[1].disabled = true;
                    } else {
                        this.disabledNC2 = false;
                        this.dicts.controlType[1].disabled = false;
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
