<template>
    <div v-if="!isAdmin" class="flex flex-1 justify-content-between flex-wrap add-in-table-header">
        <div class="flex flex-1 gap-2 flex-wrap">
            <CustomSelectOne label="Kod" v-model="newItem.species" itemLabel="code"
                             :items="dicts.programBirds" name="speciesCode" :disabled="!editing" required
                             :showErrors="showErrorMessagesNests"
                             style="display: flex; flex: 2" width="100%"/>
            <CustomSelectOne label="Gatunek" v-model="newItem.species" itemLabel="name"
                             :items="dicts.programBirds" name="speciesName" :disabled="!editing" required
                             :showErrors="showErrorMessagesNests"
                             style="display: flex; flex: 2" width="100%"/>
            <CustomInputNumber label="L. gniazd" v-model="newItem.count" name="count" required
                               :showErrors="showErrorMessagesNests" style="display: flex; flex: 2" width="100%"
                               :disabled="!editing" />
            <CustomInputNumber label="L. g. z pisklętami" v-model="newItem.nestlings" name="nestlings"
                               style="display: flex; flex: 2" width="100%"
                               :disabled="!editing" />
        </div>
        <div class="w-2 flex justify-content-end">
            <CustomButton v-if="editing" icon="pi pi-plus" label="Dodaj" @click="addNestsControl" :error="error"/>
        </div>

    </div>
</template>

<script>
    import _ from "lodash";
    import {SystemRole} from "@/utils/SystemRole";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "AddNests",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
        },

        props: {
            modelValue: {
                type: Object,
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                newItem: _.cloneDeep(EmptyStructures.getEmptyNests()),
                showErrorMessagesNests: false,
            };
        },

        methods: {
            addNestsControl() {
                // TODO add all required fields below
                if (this.newItem.species === null || this.newItem.distance === null || this.newItem.count === null) {
                    this.showErrorMessagesNests = true;
                } else {
                    this.nests.push({
                        ...this.newItem,
                        ordinal: this.nests.length + 1,
                    });
                    this.newItem = _.cloneDeep(EmptyStructures.getEmptyNests());
                    this.showErrorMessagesNests = false;
                }
            },
        },

        computed: {
            nests: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            isAdmin() {
                return SystemRole.isAdmin();
            },
        },

        watch: {
            nests: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
.add-in-table-header {
    margin-bottom: -1rem,
}
</style>
