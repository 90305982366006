<template>
    <div>
        <div v-if="$slots.selectGeobject && geobjects.length > 0" class="select-geobject-banner">
            <div class="light-green-background">
                <div v-for="(g,i) in geobjects" v-bind:key="i" @click="$emit('update:geobject',g)"
                     :class="g === geobject ? 'selected-geobject' : ''" class="select-geobject-item">
                    {{g.name}}
                </div>
            </div>
        </div>
        <div class="flex flex-column gap-5 mb-5">
            <div v-if="$slots.mainData" class="px-3">
                <slot name="mainData"/>
            </div>

            <Accordion v-if="$slots.selectGeobject" class="accordion-main-header selected-geobject-data" :activeIndex="0">
                <AccordionTab headerClass="accordion-header-green" :header="selectGeobjectHeader">
                    <div class="flex flex-column gap-5">

                        <slot name="selectGeobject"/>

                        <Accordion v-if="$slots.controlNestData" class="accordion-rounded" :activeIndex="0">
                            <AccordionTab header="Dane gniazda" headerClass="accordion-header-midgreen">
                                <slot name="controlNestData"/>
                            </AccordionTab>
                        </Accordion>

                        <Accordion v-if="$slots.controlData || $slots.birdsData || $slots.mammalsData"
                                   class="accordion-rounded" :activeIndex="0">
                            <AccordionTab headerClass="accordion-header-midgreen" :header="controlHeader">
                                <div class="flex flex-column gap-5">
                                    <div v-if="$slots.controlData"><slot name="controlData"/></div>
                                    <div v-if="$slots.birdsData">
                                        <HeaderComponent v-if="!noLightGreenHeaders" :headerText="birdsHeader" :hierarchy="2" uppercase class="mb-3"/>
                                        <slot name="birdsData"/>
                                    </div>
                                    <div v-if="$slots.mammalsData">
                                        <HeaderComponent headerText="Ssaki" :hierarchy="2" uppercase class="mb-3"/>
                                        <slot name="mammalsData"/>
                                    </div>
                                    <div v-if="additionalInformationInControl && !fewControls">
                                        <HeaderComponent v-if="!noLightGreenHeaders" headerText="Dodatkowe informacje" :hierarchy="2" uppercase class="mb-4"/>
                                        <AdditionalInformation v-model="internalAdditionalInformation" />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>

                        <FormControlsTemplate v-if="fewControls" :controls="controls" :canDeleteControl="canDeleteControl"
                                              @deleteControl="(i) => $emit('deleteControl',i)" :spaceBetweenControls="spaceBetweenControls">
                            <template v-slot:singleControl="slotProps">
                                <div class="flex flex-column gap-5">
                                    <div><slot name="controlMainData" :control="slotProps.control" :index="slotProps.index" class="mb-6"/></div>
                                    <div v-if="$slots.controlBirdsData">
                                        <HeaderComponent v-if="!noLightGreenHeaders" headerText="Ptaki" :hierarchy="2" uppercase class="mb-4 hideIfOnlyChild"/>
                                        <slot name="controlBirdsData" :control="slotProps.control" class="mb-6" :index="slotProps.index"/>
                                    </div>
                                    <div v-if="additionalInformationInControl">
                                        <HeaderComponent v-if="!noLightGreenHeaders" headerText="Dodatkowe informacje" :hierarchy="2" uppercase class="mb-4"/>
                                        <AdditionalInformation v-model="slotProps.control" />
                                    </div>
                                </div>
                            </template>
                            <template v-slot:controlsSummary>
                                <slot name="controlsSummary"/>
                            </template>
                        </FormControlsTemplate>

                        <Accordion v-if="$slots.separateBirdsData" class="accordion-rounded" :activeIndex="0">
                            <AccordionTab header="Ptaki" headerClass="accordion-header-midgreen">
                                <slot name="separateBirdsData"/>
                            </AccordionTab>
                        </Accordion>

                        <Accordion v-if="$slots.separateMammalsData" class="accordion-rounded" :activeIndex="0">
                            <AccordionTab header="Ssaki" headerClass="accordion-header-midgreen">
                                <slot name="separateMammalsData"/>
                            </AccordionTab>
                        </Accordion>

                        <Accordion v-if="$slots.controlHabitatData" class="accordion-rounded" :activeIndex="0">
                            <AccordionTab header="Dane siedliskowe" headerClass="accordion-header-midgreen">
                                <slot name="controlHabitatData"/>
                            </AccordionTab>
                        </Accordion>

                    </div>
                </AccordionTab>
            </Accordion>

            <div v-else class="px-3">
                <!-- tu jest niestety w tym div, brzydki, kopiowany kod z tego fragmentu powyżej, ale to dlatego,
                żeby nie było accordionu z nazwą wyboru geobiektu, a trudno się go inmaczej pozbyć-->
                <Accordion v-if="$slots.controlData || $slots.birdsData || $slots.mammalsData" class="accordion-rounded" :activeIndex="0">
                    <AccordionTab headerClass="accordion-header-midgreen" :header="controlHeader">
                        <div class="flex flex-column gap-5">
                            <div v-if="$slots.controlData"><slot name="controlData"/></div>
                            <div v-if="$slots.birdsData">
                                <HeaderComponent headerText="Ptaki" :hierarchy="2" uppercase class="mb-3"/>
                                <slot name="birdsData"/>
                            </div>
                            <div v-if="$slots.mammalsData">
                                <HeaderComponent headerText="Ssaki" :hierarchy="2" uppercase class="mb-3"/>
                                <slot name="mammalsData"/>
                            </div>
                            <div v-if="additionalInformationInControl && !fewControls">
                                <HeaderComponent headerText="Dodatkowe informacje" :hierarchy="2" uppercase class="mb-4"/>
                                <AdditionalInformation v-model="internalAdditionalInformation" />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>

                <FormControlsTemplate v-if="fewControls" :controls="controls"
                                      :canDeleteControl="canDeleteControl" @deleteControl="(i) => $emit('deleteControl',i)"
                                      :spaceBetweenControls="spaceBetweenControls">
                    <template v-slot:singleControl="slotProps">
                        <div class="flex flex-column gap-5">
                            <div><slot name="controlMainData" :control="slotProps.control" :index="slotProps.index" class="mb-6"/></div>
                            <div v-if="$slots.controlBirdsData">
                                <HeaderComponent headerText="Ptaki" :hierarchy="2" uppercase class="mb-4 hideIfOnlyChild"/>
                                <slot name="controlBirdsData" :control="slotProps.control" class="mb-6"/>
                            </div>
                            <div v-if="additionalInformationInControl">
                                <HeaderComponent headerText="Dodatkowe informacje" :hierarchy="2" uppercase class="mb-4"/>
                                <AdditionalInformation v-model="slotProps.control" />
                            </div>
                        </div>
                    </template>
                    <template v-slot:controlsSummary>
                        <slot name="controlsSummary"/>
                    </template>
                </FormControlsTemplate>
            </div>

            <Accordion v-if="$slots.otherBirdsData" class="accordion-main-header" :activeIndex="0">
                <AccordionTab header="Ptaki na powierzchni" headerClass="accordion-header-green">
                    <slot name="otherBirdsData"/>
                </AccordionTab>
            </Accordion>

            <Accordion v-if="$slots.broodEffect" class="accordion-main-header" :activeIndex="0">
                <AccordionTab header="Efekty lęgów" headerClass="accordion-header-green">
                    <slot name="broodEffect"/>
                </AccordionTab>
            </Accordion>

            <Accordion v-if="$slots.observationConditions" class="accordion-main-header" :activeIndex="0">
                <AccordionTab header="Warunki obserwacji" headerClass="accordion-header-green">
                    <slot name="observationConditions"/>
                </AccordionTab>
            </Accordion>

            <Accordion v-if="$slots.nestData" class="accordion-main-header" :activeIndex="0">
                <AccordionTab header="Dane gniazda" headerClass="accordion-header-green">
                    <slot name="nestData"/>
                </AccordionTab>
            </Accordion>

            <Accordion v-if="$slots.habitatData" class="accordion-main-header" :activeIndex="0">
                <AccordionTab header="Dane siedliskowe" headerClass="accordion-header-green">
                    <slot name="habitatData"/>
                </AccordionTab>
            </Accordion>

            <Accordion v-if="additionalInformationInMain" class="accordion-main-header" :activeIndex="0">
                <AccordionTab header="Dodatkowe informacje" headerClass="accordion-header-green">
                    <AdditionalInformation v-model="internalAdditionalInformation" />
                </AccordionTab>
            </Accordion>

            <Accordion class="accordion-main-header" :activeIndex="0">
                <AccordionTab header="Zagrożenia" headerClass="accordion-header-green">
                    Tu będzie znajdowała się sekcja "Zagrożenia"
                </AccordionTab>
            </Accordion>

            <Accordion class="accordion-main-header" :activeIndex="0">
                <AccordionTab header="Załączniki" headerClass="accordion-header-green">
                    <CustomFormFileUpload ref="fileUploader" class="observations-file-upload" :disabled="!editing"/>
                </AccordionTab>
            </Accordion>
        </div>
    </div>
</template>

<script>
    import Accordion from "primevue/accordion";
    import AccordionTab from "primevue/accordiontab";
    import FormControlsTemplate from "@/views/form/FormControlsTemplate.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import AdditionalInformation from "@/components/formSharedComponents/AdditionalInformation.vue";
    import CustomFormFileUpload from "@/components/form/CustomFormFileUpload.vue";

    export default {
        name: "FormObservationsTemplate",

        components: {
            CustomFormFileUpload,
            AdditionalInformation,
            HeaderComponent,
            FormControlsTemplate,
            Accordion,
            AccordionTab,
        },

        props: {
            geobjectType: {
                type: String,
                default: "stanowisko",
            },
            birdsHeader: {
                type: String,
                default: "Ptaki",
            },
            geobjects: {
                type: Array,
                default: () => [],
            },
            geobject: {
                type: Object,
            },
            controls: {
                type: Array,
                default: () => [],
            },
            canDeleteControl: {
                type: Boolean,
                default: false,
            },
            additionalInformation: {
                type: Object,
                default: () => {},
            },
            additionalInformationInMain: {
                type: Boolean,
                default: false,
            },
            noAdditionalInformation: {
                type: Boolean,
                default: false,
            },
            spaceBetweenControls: {
                type: Boolean,
                default: false,
            },
            noLightGreenHeaders: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing"],

        emits: ["deleteControl", "update:additionalInformation", "update:geobject"],

        data() {
            return {
                banner: null,
                selectSection: null,
                selectedData: null,
                selectedDataTop: null,
                startDisplayingBanner: null,
                bannerListener: null,
            };
        },

        mounted() {
            // kod poniżej wylicza kiedy na ekranie znajduje się sekcja danych dotyczących wybranego geobiektu
            // i wówczas wyświetla baner umożliwiający szybką zmianę przeglądanego geobiektu

            this.banner = document.querySelector(".select-geobject-banner");
            this.selectSection = document.querySelector(".select-geobject-section");
            this.selectedData = document.querySelector(".selected-geobject-data");

            if (this.banner != null && this.selectSection != null && this.selectedData != null) {
                const rectSelectedData = this.selectedData.getBoundingClientRect();
                this.startDisplayingBanner = this.selectSection.getBoundingClientRect().bottom - 180;
                this.selectedDataTop = rectSelectedData.top;
                setTimeout(() => {
                    const endDisplayingBanner = this.selectedDataTop + rectSelectedData.height - 80;
                    this.bannerListener = (event) => this.updateBannerVisibility(endDisplayingBanner, event);
                    window.addEventListener("scroll", this.bannerListener, false);
                }, 500);
            }
        },

        methods: {
            async uploadFilesInChild() {
                if (this.$refs.fileUploader) {
                    await this.$refs.fileUploader.saveChanges();
                }
            },
            updateBannerVisibility(endDisplayingBanner) {
                if (window.scrollY > this.startDisplayingBanner
                    && window.scrollY < endDisplayingBanner) {
                    this.banner.style.display = "flex";
                } else {
                    this.banner.style.display = "none";
                }
            },
        },
        computed: {
            internalAdditionalInformation: {
                get() { return this.additionalInformation; },
                set(value) { this.$emit("update:additionalInformation", value); },
            },
            selectGeobjectHeader() {
                if (this.geobjectType === "punkt") return "Punkty";
                if (this.geobjectType === "transekt") return "Odcinki";
                return "Stanowiska";
            },
            controlHeader() {
                let header = "Kontrola ";
                if (this.geobjectType === "stanowisko") header += "stanowiska";
                if (this.geobjectType === "punkt") header += "punktu";
                if (this.geobjectType === "transekt") header += "odcinka";
                return header;
            },
            additionalInformationInControl() {
                return !this.additionalInformationInMain && !this.noAdditionalInformation;
            },
            fewControls() {
                return this.controls != null && this.controls.length > 0;
            },
        },

        watch: {
            internalAdditionalInformation: {
                handler(value) { this.$emit("update:additionalInformation", value); },
                deep: true,
            },
            geobject() {
                if (this.banner != null && this.selectSection != null && this.selectedData != null) {
                    setTimeout(() => {
                        const rectSelectedData = this.selectedData.getBoundingClientRect();
                        const endDisplayingBanner = this.selectedDataTop + rectSelectedData.height - 80;
                        window.removeEventListener("scroll", this.bannerListener);
                        this.bannerListener = (event) => this.updateBannerVisibility(endDisplayingBanner, event);
                        window.addEventListener("scroll", this.bannerListener, false);
                    }, 500);
                }
            },
        },
    };
</script>

<style lang="scss">
@import "../../assets/theme/mytheme/_variables.scss";
@import "../../views/assets/scss/select-geobject";
.select-geobject-banner {
    display: none;
    position: fixed;
    z-index: 1000;
    top: $top-bar-height;
    // left: $sidebar-width;
    transition: left $menu-transition-duration;
    cursor: default;
    text-align: left;
    height: 50px;
    width: 100%;
    background: white;
    border-bottom: 1px solid var(--surface-0);
    animation-name: slide;
    animation-duration: 0.5s;
    .light-green-background {
        display: flex;
        height: 50px;
        width: 100%;
        background: var(--secondary-color-light);
    }
}
@keyframes slide {
    from {top: calc($top-bar-height - 50px);}
    to {top: $top-bar-height;}
}
.select-geobject-item {
    padding: 1rem;
    font-size: 1.07rem;
    text-transform: uppercase;
    font-weight: bold;
}
.selected-geobject {
    background: var(--secondary-color-mid);
    color: var(--text-color-black);
}
.hideIfOnlyChild:only-child {
    display: none !important;
}
</style>
