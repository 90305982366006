<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position.control">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition :field="field" v-model="position" :positions="positions" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <ControlTypeAndDates v-model="position.control" />
            <ControlWeather v-model="position.control" hideAudibility />
            <div class="flex column-gap-2 pr-4 flex-direction-column">
                <CustomSelectOne label="Siedlisko w miejscu nocowania gęsi"
                                 name="habitatType" :items="dicts.habitatType" :showErrors="showErrorMessages"
                                 required class="w-3 max-width" v-model="position.habitatType" :disabled="!editing" longLabelSelect/>
                <CustomSelectOne class="w-3 max-width" label="Metoda obserwacji" v-model="position.controlMethod" name="controlMethod"
                                 :items="dicts.controlMethod" required :disabled="!editing"
                                 :showErrors="showErrorMessages"/>
            </div>
        </template>

        <template v-slot:birdsData>
            <ObservationBirdsMNG v-model="position.flocks" v-model:posNoBirds="position.noBirds" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import ObservationBirdsMNG from "./ObservationBirdsMNG.vue";
    import ControlWeather from "@/components/formSharedComponents/ControlWeather.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";

    export default {
        name: "FormMNGObservations",

        components: {
            ControlTypeAndDates,
            CustomSelectOne,
            FormObservationsTemplate,
            ControlWeather,
            ObservationsSelectPosition,
            ObservationBirdsMNG,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            editable: {
                type: Boolean,
                default: false,
            },
            step: {
                type: Number,
                required: true,
            },
        },

        emits: ["update:step"],

        inject: ["showErrorMessages", "editing", "dicts"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMNGPosition()),
                activeIndex: 0,
                control: _.cloneDeep(EmptyStructures.getEmptyMNGControl()),
            };
        },

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
            for (let i = 0; i < this.positions.length; i += 1) {
                if (this.positions[i].control === null) {
                    this.positions[i].control = _.cloneDeep(EmptyStructures.getEmptyMNGControl());
                }
            }
        },

        computed: {
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            position: {
                handler(value) {
                    if (value.flocks === null) {
                        this.position.flocks = [];
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
@import "../../../../assets/theme/mytheme/variables";

.colorRedBG {
    .p-accordion-header-link.p-accordion-header-action {
        background-color: $color-red !important;
        color: white !important;
    }
}

@media screen and (max-width: 700px) {
    .flex-direction-column {
        flex-direction: column;
        padding-right: 0 !important;
        .max-width {
            width: 100% !important;
        }
    }
}
</style>
