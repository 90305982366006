<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              :controls="controls">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition :field="field" v-model="position" :positions="positions" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlMainData="slotProps">
            <ControlTypeAndDates v-model="slotProps.control" :index="slotProps.index" controlTypeDates />
            <ControlWeather v-model="slotProps.control" hideVisibility hideAudibility />
        </template>
        <template v-slot:controlBirdsData="slotProps">
            <div class="flex column-gap-2 flex-direction-column">
                <CustomCheckbox label="Brak ptaków" name="noBirds" v-model="slotProps.control.noBirds" class="w-2 max-width" :disabled="!editing"/>
                <CustomInputNumber v-if="!slotProps.control.noBirds" label="L. dorosłych os." name="adults"
                                   v-model="slotProps.control.adults" class="w-2 max-width"
                                   :required="!slotProps.control.noBirds"
                                   :showErrors="showErrorMessages" :disabled="!editing"/>
                <CustomInputNumber v-if="!slotProps.control.noBirds" label="L. os. młod." name="young"
                                   v-model="slotProps.control.young" class="w-2 max-width"
                                   :disabled="!editing"/>
                <CustomInputNumber v-if="!slotProps.control.noBirds" label="L. piskląt" name="nestlings"
                                   v-model="slotProps.control.nestlings" class="w-2 max-width" :disabled="!editing"/>
                <CustomSelectOne v-if="!slotProps.control.noBirds" label="Kryterium lęgowości" name="nestingCriteria"
                                 v-model="slotProps.control.nestingCriteria" class="w-6 max-width" :items="dicts.nestingCriteria"
                                 :showErrors="showErrorMessages" tooltip
                                 :required="!slotProps.control.noBirds"
                                 :itemLabel="getDictLabelWithCodePrefix" :disabled="!editing"/>
            </div>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import ControlWeather from "@/components/formSharedComponents/ControlWeather.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "FormMKRObservations",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomCheckbox,
            ControlTypeAndDates,
            FormObservationsTemplate,
            ControlWeather,
            ObservationsSelectPosition,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:step"],

        data() {
            return {
                positions: [],
                activeIndex: 0,
                position: _.cloneDeep(EmptyStructures.getEmptyMKRPosition()),
                control: _.cloneDeep(EmptyStructures.getEmptyMKRControl()),
                controls: [],
            };
        },

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
        },

        methods: {
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 80) {
                    return _.truncate(text, {
                        length: 80,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            position: {
                handler(value) {
                    this.controls[0] = value.control1;
                    this.controls[1] = value.control2;
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
@import "../../../assets/theme/mytheme/variables";

@media screen and (max-width: 1000px) {
    .flex-direction-column {
        flex-direction: column;
        .max-width {
            width: 100% !important;
        }
    }
}
</style>
