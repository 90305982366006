<template>
    <Toast />
    <div class="data-aggregates-view p-3">
        <ImportSemiRawDataDialog v-model="showImportDialog" />
        <div class="monitoring-program-semiraw-search-criteria flex justify-content-end flex-column">
            <div class="flex gap-3 flex-wrap">
                <GeographicalFilters v-model="searchCriteria" v-model:matchMode="searchCriteria.matchMode"
                                     :filterBy="matchModeOptions" />
                <div class="flex align-self-end justify-content-end field">
                    <CustomButton label="Wyczyść" @click="clear"/>
                </div>
            </div>
            <div class="flex align-items-center gap-3 field justify-content-end">
                <CustomButton label="Eksportuj" @click="exportSemiRawData"/>
                <CustomButton label="Importuj" @click="showImportDialog = true" />
            </div>
        </div>
        <SemiRawDataTable v-model="searchCriteria" isFilteredDicts/>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import SemiRawDataTable from "@/views/semiRawData/components/SemiRawDataTable.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import ImportSemiRawDataDialog
        from "@/views/semiRawData/components/ImportSemiRawDataDialog.vue";
    import {FileUtils} from "@/utils/FileUtils";
    import GeographicalFilters from "@/components/filters/GeographicalFilters.vue";

    export default {
        name: "SemiRawDataView",

        components: {
            ImportSemiRawDataDialog,
            CustomButton,
            SemiRawDataTable,
            GeographicalFilters,
            Toast,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                showImportDialog: false,
                matchModeOptions: [
                    {value: "FIELD", label: "Powierzchni"},
                ],
            };
        },

        methods: {
            clear() {
                this.$refs.geographicalFilters.clear();
            },
            getClearSearchCriteria() {
                return SearchCriteria.getClearSearchCriteriaSemiRawData();
            },
            exportSemiRawData() {
                FileUtils.downloadFile(
                    {body: this.searchCriteria}, "dane-skumulowane.xlsx", "/semiRawData/search/export");
            },
        },
    };
</script>

<style lang="scss">
</style>
