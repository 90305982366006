<template>
    <Dialog class="w-3 pos-without-coor-dialog" header="Stanowiska bez wprowadzonej lokalizacji" v-model:visible="internalVisible" :draggable="false" :closable="false" modal>
        <div class="flex flex-column gap-4">
            <ul class="list-none flex flex-column p-1" style="height: 400px; overflow-y: scroll;">
                <li v-for="(position) in positionsWithoutCoordinatesList" :key="position.id"
                    class="mb-2 font-bold text-2xl flex align-items-center justify-content-between">
                    {{ position.name }} <CustomButton label="Edytuj" icon="pi pi-pencil" @click="goToEditPosition(position)" />
                </li>
            </ul>
            <CustomButton class="align-self-end" label="Powrót do widoku programu" @click="backToProgramView" />
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";

    export default {
        name: "PositionWithoutCoordinatesListDialog",

        components: {
            Dialog,
            CustomButton,
        },

        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            positionsWithoutCoordinatesList: {
                type: Array,
            },
        },

        data() {
            return {
                coordinates: {
                    longitude: null,
                    latitude: null,
                },
                mapCoordinates: [],
                showErrorMessages: false,
            };
        },

        emits: ["update:visible", "posWithoutCoor"],

        inject: ["programEditionId"],

        methods: {
            goToEditPosition(pos) {
                this.$emit("posWithoutCoor", pos);
            },
            backToProgramView() {
                this.$router.push(`/monitoring-program/${this.programEditionId}`);
            },
        },

        computed: {
            internalVisible: {
                get() { return this.visible; },
                set(value) { this.$emit("update:visible", value); },
            },
        },
    };
</script>

<style lang="scss">
    @import "../../../assets/theme/mytheme/variables";
    @import "../../../views/assets/scss/dialogs";

    .pos-without-coor-dialog {
        min-width: 320px;
        .p-dialog-content {
            padding-top: 1rem !important;
        }
    }
</style>
