<template>
    <div v-if="!loading" class="px-3">
        <h2 class="pt-4 pb-3">Szczegóły programu</h2>
        <ProgramMainInfo v-model="program" />
        <ProgramEmailSendingTable v-model="program.emailSendingRules" />
    </div>
</template>

<script>
    import {getProgramById} from "@/swagger/vue-api-client";
    import ProgramMainInfo from "./components/ProgramMainInfo.vue";
    import ProgramEmailSendingTable from "./components/ProgramEmailSendingTable.vue";

    export default {
        name: "ProgramDetailsView",

        components: {
            ProgramMainInfo,
            ProgramEmailSendingTable,
        },

        data() {
            return {
                loading: true,
                program: null,
            };
        },

        beforeMount() {
            getProgramById({id: this.$route.params.programName})
                .then((response) => {
                    this.program = response.data;
                    this.loading = false;
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    }
                });
        },
    };
</script>

<style lang="scss">
</style>
