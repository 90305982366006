<template>
    <FormObservationsTemplate ref="observationsTemplate" geobjectType="transekt" v-model:geobject="transect" :geobjects="transects"
                              v-model:additionalInformation="internalForm" additionalInformationInMain>
        <template v-slot:mainData>
            <TransectFormMainData v-model:form="internalForm" showCountMammals showCountSex :numberOfTransects="2"/>
        </template>
        <template v-slot:selectGeobject>
            <SelectTransect :transects="transects" :field="form.field.shape" v-model:step="internalStep"
                            v-model:activeIndex="activeIndex" :segmentsInTransects="segmentsInTransects"/>
        </template>
        <template v-slot:separateBirdsData>
            <FormBirds v-model:noBirds="transect.noBirds" v-model:birds="transect.formBirds" :countSex="form.sex" :minWidth="1320" />
        </template>
        <template v-slot:separateMammalsData v-if="form.mammals">
            <FormMammals :index="activeIndex" v-model:noMammals="transect.noMammals" v-model:mammals="transect.formMammals"/>
        </template>
        <template v-slot:nestData>
            <FormNests v-model:noNests="internalForm.noNests" v-model:nests="internalForm.nestsList"/>
        </template>
        <template v-slot:habitatData>
            <TransectHabitatData v-model:form="internalForm"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import TransectFormMainData from "@/components/transectForm/TransectFormMainData.vue";
    import SelectTransect from "@/components/transectForm/selectTransect/SelectTransect.vue";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import FormMammals from "@/components/formMammals/FormMammals.vue";
    import FormNests from "@/components/formNests/FormNests.vue";
    import TransectHabitatData from "@/components/transectForm/habitatData/TransectHabitatData.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";

    export default {
        name: "FormMPPLObservations",

        components: {
            FormObservationsTemplate,
            TransectHabitatData,
            FormNests,
            FormMammals,
            FormBirds,
            SelectTransect,
            TransectFormMainData,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
        },

        inject: ["editing"],

        data() {
            return {
                transects: [],
                transect: _.cloneDeep(EmptyStructures.getEmptyTransect()),
                activeIndex: null,
            };
        },

        emits: ["update:form", "update:step"],

        beforeMount() {
            this.transects = this.form.formTransects;
            if (this.transects.length > 0) {
                this.transects.forEach((t, i) => {
                    if (!(t.notAudited === true) && this.activeIndex == null) this.activeIndex = i;
                });
            }
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
            segmentsInTransects() {
                const first5 = this.transects.filter((t) => t.ordinal <= 5);
                const second5 = this.transects.filter((t) => t.ordinal > 5 && t.ordinal <= 10);
                return [first5, second5];
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            activeIndex(value) { this.transect = this.transects[value]; },
            "internalForm.noNests"(value) {
                this.internalForm.nests = !value;
            },
            transect(value) {
                this.activeIndex = this.transects.indexOf(value);
            },
        },
    };
</script>

<style lang="scss">
</style>
