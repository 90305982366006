<template>
    <div class="p-fluid custom-input field relative custom-time-picker">
        <span class="p-float-label">
            <Calendar :class="computedClasses" :id="name" v-model="internalValue"
                      :showTime="showTime" timeOnly @date-select="onChange" hourFormat="24"
                      :manualInput="true" :showOnFocus="true" :showIcon="showIcon" :icon="icon"
                      :disabled="disabled" @focus="onFocus" @change="convertTime"
                      @click="focusOnTimePicker" @input="addSeparator" />
            <CustomLabel :name="name" :label="label" :required="required" :disabled="disabled" />
        </span>
        <CustomValidationMessage :errorMessage="errorMessage" />
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import Calendar from "primevue/calendar";
    import {useField} from "vee-validate";
    import CustomValidationMessage from "./inner/CustomValidationMessage";
    import CustomLabel from "./inner/CustomLabel";

    export default {
        name: "CustomTimePicker",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            showTime: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
            rules: undefined,
            showIcon: {
                type: Boolean,
                default: true,
            },
            icon: {
                type: String,
                default: "pi pi-clock",
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            customErrorWithoutMessage: {
                type: Boolean,
                default: false,
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ["update:modelValue"],

        components: {Calendar, CustomValidationMessage, CustomLabel},

        computed: {
            computedClasses() {
                if (this.customErrorMessage !== "" || this.customErrorWithoutMessage || this.showRequiredError) {
                    return "p-invalid";
                }
                return "";
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue === "");
            },
            internalValue: {
                get() {
                    return this.convertTimeToDateFormat(this.modelValue);
                },
                set(value) {
                    this.selectedValue = value;
                    this.handleChange(value);
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            onChange(event) {
                this.handleChange(event);
            },
            focusOnTimePicker() {
                document.querySelector(".custom-time-picker input.p-inputtext.p-component").addEventListener("keydown", (e) => {
                    if (e.key === "ArrowUp") {
                        const timePickerFocus = document.querySelector(".p-timepicker .p-link");
                        if (timePickerFocus) {
                            timePickerFocus.focus();
                        }
                    }
                });
            },
            onFocus() {
                this.setTimeOnSix();
                this.useArrowsToSetTime();
                const regLet = /[a-zA-Z]/;
                document.querySelector(".p-calendar-timeonly .p-inputtext").addEventListener("keypress", (e) => {
                    if (regLet.test(e.key)) {
                        e.preventDefault();
                    }
                    if (e.target.value.length > 4) {
                        e.target.value = "";
                    }
                });
            },
            useArrowsToSetTime() {
                setTimeout(() => {
                    document.querySelector(".p-timepicker").addEventListener("keydown", (e) => {
                        e.preventDefault();
                        if (e.key === "ArrowUp") {
                            if (e.ctrlKey) {
                                document.querySelectorAll(".p-hour-picker .p-link")[0].dispatchEvent(new Event("mousedown"));
                            } else {
                                document.querySelectorAll(".p-minute-picker .p-link")[0].dispatchEvent(new Event("mousedown"));
                            }
                        }
                    });
                    document.querySelector(".p-timepicker").addEventListener("keyup", (e) => {
                        e.preventDefault();
                        if (e.key === "ArrowUp") {
                            document.querySelectorAll(".p-minute-picker .p-link")[0].dispatchEvent(new Event("mouseup"));
                            document.querySelectorAll(".p-hour-picker .p-link")[0].dispatchEvent(new Event("mouseup"));
                        }
                    });
                    document.querySelector(".p-timepicker").addEventListener("keydown", (e) => {
                        e.preventDefault();
                        if (e.key === "ArrowDown") {
                            if (e.ctrlKey) {
                                document.querySelectorAll(".p-hour-picker .p-link")[1].dispatchEvent(new Event("mousedown"));
                            } else {
                                document.querySelectorAll(".p-minute-picker .p-link")[1].dispatchEvent(new Event("mousedown"));
                            }
                        }
                    });
                    document.querySelector(".p-timepicker").addEventListener("keyup", (e) => {
                        e.preventDefault();
                        if (e.key === "ArrowDown") {
                            document.querySelectorAll(".p-minute-picker .p-link")[1].dispatchEvent(new Event("mouseup"));
                            document.querySelectorAll(".p-hour-picker .p-link")[1].dispatchEvent(new Event("mouseup"));
                        }
                    });
                }, 100);
            },
            setTimeOnSix() {
                if (!this.internalValue || this.internalValue === "") {
                    this.internalValue = new Date(0, 0, 0, 6, 0);
                }
            },
            addSeparator(e) {
                if (!e.target.value.includes(":")
                    && e.target.value.length === 2
                    && e.inputType === "insertText") {
                    e.target.value += ":";
                }
            },
            convertTime(ctx) {
                if (ctx.target.value != null && ctx.target.value !== "") {
                    if (ctx.target.value.includes(":") && ctx.target.value.length === 5) return;
                    if (ctx.target.value.length === 1) {
                        this.internalValue = new Date(0, 0, 0, 0, 0);
                    } else if (ctx.target.value.split(":")[0].length === 1) {
                        this.internalValue = new Date(
                            0, 0, 0, "0" + ctx.target.value.split(":")[0], ctx.target.value.split(":")[1],
                        );
                    } else if (!ctx.target.value.includes(":")) {
                        let num2Time = ctx.target.value;
                        if (num2Time < 100 && num2Time > 0) num2Time *= 100;
                        if (num2Time === "0" || num2Time === "00" || num2Time === "000") num2Time = "0000";
                        /* eslint-disable-next-line */
                        let [_, hh, mm] = num2Time.toString().match(/(\d{1,2})(\d{2})$/);
                        if (hh > 23) hh = "00";
                        if (mm > 59) mm = "00";
                        this.internalValue = new Date(0, 0, 0, hh.padStart(2, "0"), mm);
                    }
                }
            },
            convertTimeToDateFormat(time) {
                if (time && time.length === 5) {
                    const t = new Date();
                    const splitedTime = time.split(":");
                    t.setHours(splitedTime[0]);
                    t.setMinutes(splitedTime[1]);
                    return t;
                }
                return this.modelValue;
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style lang="scss" scoped>
</style>
