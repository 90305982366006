<template>
    <CustomCheckbox label="Brak ssaków" v-model="internalNoMammals" :disabled="!editing"
                    name="noMammals" :error="showError()"/>
    <div class="overflow-x-auto">
        <DataTable v-if="!internalNoMammals" :value="internalMammals" v-model:editingRows="editingRows" editMode="row"
                   dataKey="ordinal" @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'" class="navigate-table" @click="arrowsNavigateTable"
                   style="min-width: 1200px;">
            <template #header>
                <AddMammalsMLS v-model="internalMammals" :error="showError()" />
            </template>
            <Column header="Lp." field="ordinal" style="width: 4.1666%;" />
            <Column header="Kod" class="w-1" field="mammal">
                <template #body="slotProps">
                    {{ slotProps.data.mammal?.code }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne label="Kod" name="speciesCode" v-model="data[field]"
                                     :items="dicts.programMammal" itemLabel="code" />
                </template>
            </Column>
            <Column header="Gatunek" class="w-1" field="mammal">
                <template #body="slotProps">
                    {{ slotProps.data.mammal?.namePl ? slotProps.data.mammal?.namePl : slotProps.data.mammal?.name }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne label="Gatunek" name="speciesName" v-model="data[field]"
                                     :items="dicts.programMammal" itemLabel="name" />
                </template>
            </Column>
            <Column header="Liczba osobników" class="w-2" field="count">
                <template #editor="{data,field}">
                    <CustomInputNumber label="Liczba osobników" name="count" v-model="data[field]" :min="1" />
                </template>
            </Column>
            <Column header="Kategoria odległości" class="w-2" field="distance">
                <template #body="slotProps">
                    {{ slotProps.data.distance?.label }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne label="Kategoria odległości" name="distance" v-model="data[field]"
                                     :items="dicts.distance" />
                </template>
            </Column>
            <Column class="w-4"/>
            <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
            <Column v-if="editing" style="width: 4.1666%; text-align: center;">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteMammalObservation(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import TableUtils from "@/utils/TableUtils";
    import AddMammalsMLS from "@/views/formMLS/components/AddMammalsMLS.vue";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "FormMammalsMLS",

        components: {
            AddMammalsMLS,
            DataTable,
            Column,
            CustomInputNumber,
            CustomSelectOne,
            CustomCheckbox,
            CustomButton,
        },

        props: {
            noMammals: {
                type: Boolean,
            },
            mammals: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:noMammals", "update:mammals"],

        data() {
            return {
                editingRows: ref([]),
            };
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.internalMammals[index] = newData;
            },
            deleteMammalObservation(item) {
                const index = this.internalMammals.indexOf(item);
                this.internalMammals.splice(index, 1);
            },
            showError() {
                return this.showErrorMessages && ((this.internalMammals == null
                    || this.internalMammals.length === 0) && !(this.internalNoMammals === true));
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return item.code + " - " + item.label;
            },
        },

        computed: {
            internalNoMammals: {
                get() { return this.noMammals; },
                set(value) { this.$emit("update:noMammals", value); },
            },
            internalMammals: {
                get() { return this.mammals; },
                set(value) { this.$emit("update:mammals", value); },
            },
        },

        watch: {
            internalMammals: {
                handler(value) { this.$emit("update:mammals", value); },
                deep: true,
            },
            mammals: {
                handler(value) {
                    TableUtils.addOrdinal(value);
                },
                immediate: true,
            },
        },
    };
</script>

<style lang="scss">
</style>
