<template>
    <div class="mammals-table overflow-x-auto">
        <DataTable :value="nests" v-model:editingRows="editingRows" editMode="row" dataKey="id"
                   @row-edit-save="onRowEditSave" class="navigate-table" @click="arrowsNavigateTable"
                   style="min-width: 880px">
            <template #header>
                <AddNests v-model="nests" class="ml-6" :error="error" />
            </template>
            <Column header="Lp." field="ordinal" class="w-3rem"/>
            <Column header="Kod" field="species" class="w-2">
                <template #body="slotProps">
                    {{ slotProps.data.species.code }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne v-model="data[field]" itemLabel="code" :items="dicts.programBirds"
                                     name="speciesCode" />
                </template>
            </Column>
            <Column header="Gatunek" field="species" class="w-2">
                <template #body="slotProps">
                    {{ slotProps.data.species.name }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne v-model="data[field]" itemLabel="name" :items="dicts.programBirds"
                                     name="speciesName" />
                </template>
            </Column>
            <Column header="L. gniazd" field="count" class="w-2">
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="count" />
                </template>
            </Column>
            <Column header="L. g. z pisklętami" field="nestlings" class="w-2">
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="nestlings" />
                </template>
            </Column>
            <Column v-if="editing" :rowEditor="true" style="width: 120px; text-align: center;" class="w-1"/>
            <Column v-if="editing" style="width: 60px; text-align: center;" class="w-1">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteNestObservation(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import AddNests from "@/components/formNests/AddNests.vue";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "NestsTable",

        components: {
            AddNests,
            CustomButton,
            CustomInputNumber,
            CustomSelectOne,
            DataTable,
            Column,
        },

        props: {
            modelValue: {
                type: Array,
                default: () => [],
            },
            error: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts"],

        data() {
            return {
                editingRows: ref([]),
            };
        },

        emits: ["update:modelValue"],

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.nests[index] = newData;
            },
            deleteNestObservation(item) {
                const index = this.nests.indexOf(item);
                this.nests.splice(index, 1);
            },
        },

        computed: {
            nests: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            nests: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
