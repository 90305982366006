<template>
    <Toast/>
    <div v-if="loadedMainData" class="monitoring-program-view px-3">
        <div class="monitoring-program-data-management">
            <HeaderComponent headerText="Zarządzanie programem" class="mt-0 mb-3"/>
            <div class="flex justify-content-between">
                <MonitoringProgramDataManagement :programEdition="programEdition"
                                                 :programEditionYear="programEdition.year" v-model="editCast"/>
                <CastAndResultsManagementButtons @reloadProgramState="getProgramEditionData(true)" />
            </div>
        </div>
        <div class="info-summary-conatiner flex w-full gap-5 mt-3">
            <MonitoringProgramInformation v-model:programEdition="programEdition" v-model="editCast" />
            <MonitoringProgramSummary :controlStatistics="programEdition.controlStatistics" />
        </div>
        <div class="flex flex-column" v-if="loadedFilters">
            <MonitoringProgramRegionalCoordinators v-if="!isObserver && programEdition.hasRegionalCoordinators"
                                                   v-model:editCast="editCast"
                                                   v-model="programEdition.programRegionalCoordinators"/>
            <MonitoringProgramObservers v-if="!isObserver" v-model="editCast"
                                        @reloadProgramState="getProgramEditionData(true)"
                                        ref="monitoringProgramObservers" />
            <MonitoringProgramControls ref="monitoringProgramControls"/>
            <MonitoringProgramEventLogs v-if="!isObserver" ref="monitoringProgramEventLogs"/>
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import {computed} from "vue";
    import {SystemRole} from "@/utils/SystemRole";
    import {
        getFiltersInProgramEdition,
        getProgramEdition,
    } from "@/swagger/vue-api-client";
    import MonitoringProgramDataManagement
        from "@/views/monitoringProgram/components/MonitoringProgramDataManagement.vue";
    import CastAndResultsManagementButtons
        from "@/views/monitoringProgram/components/CastAndResultsManagementButtons.vue";
    import MonitoringProgramInformation from "@/views/monitoringProgram/components/MonitoringProgramInformation.vue";
    import MonitoringProgramSummary from "@/views/monitoringProgram/components/MonitoringProgramSummary.vue";
    import MonitoringProgramObservers
        from "@/views/monitoringProgram/components/observer/MonitoringProgramObservers.vue";
    import MonitoringProgramEventLogs from "@/views/monitoringProgram/components/eventLogs/MonitoringProgramEventLogs";
    import MonitoringProgramRegionalCoordinators
        from "./components/regionalCoordinators/MonitoringProgramRegionalCoordinators.vue";
    import MonitoringProgramControls
        from "@/views/monitoringProgram/components/controls/MonitoringProgramControls.vue";
    import HeaderComponent from "@/components/HeaderComponent.vue";

    export default {
        name: "MonitoringProgramView",

        components: {
            MonitoringProgramControls,
            MonitoringProgramObservers,
            MonitoringProgramSummary,
            MonitoringProgramInformation,
            CastAndResultsManagementButtons,
            MonitoringProgramDataManagement,
            MonitoringProgramEventLogs,
            MonitoringProgramRegionalCoordinators,
            HeaderComponent,
            Toast,
        },

        data() {
            return {
                programEdition: null,
                editCast: false,
                fields: [],
                subfields: [],
                observers: [],
                loadedMainData: false,
                loadedFilters: false,
            };
        },

        provide() {
            return {
                programCode: computed(() => this.programEdition.program),
                fields: computed(() => this.fields),
                subfields: computed(() => this.subfields),
                hasSubfields: computed(() => this.programEdition.hasSubfields),
                regionalCoordinators: computed(() => this.programEdition.programRegionalCoordinators),
                nationalCoordinators: computed(() => this.programEdition.nationalCoordinators),
                hasRegionalCoordinators: computed(() => this.programEdition.hasRegionalCoordinators),
                hasAllControls: computed(() => this.programEdition.allControls),
                observers: computed(() => this.observers),
                programEditionState: computed(() => this.programEdition.state),
                programType: computed(() => this.programEdition.programType),
                year: computed(() => this.programEdition.year),
            };
        },

        beforeMount() {
            this.getProgramEditionData();
            this.getObserversAndFields();
        },

        methods: {
            getProgramEditionData(reloadOthers = false) {
                getProgramEdition({id: this.$route.params.id})
                    .then((response) => {
                        this.programEdition = response.data;
                        console.log(this.programEdition);
                        if (this.programEdition.state !== "CAST_NOT_ENTERED" && reloadOthers) {
                            setTimeout(() => {
                                this.$refs.monitoringProgramObservers?.search();
                                this.$refs.monitoringProgramControls?.search();
                                this.$refs.monitoringProgramEventLogs?.search();
                            }, 1000);
                        }
                        this.loadedMainData = true;
                    })
                    .catch((err) => {
                        if (err.response?.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                    });
            },
            getObserversAndFields() {
                getFiltersInProgramEdition({programEditionId: this.$route.params.id, requestType: "observers"})
                    .then((response) => {
                        this.observers = response.data;
                        return getFiltersInProgramEdition({
                            programEditionId: this.$route.params.id,
                            requestType: "fields",
                        });
                    })
                    .then((response) => {
                        this.fields = response.data;
                        this.loadedFilters = true;
                    })
                    .catch((error) => { console.log(error); });
            },
        },

        computed: {
            isObserver() {
                return SystemRole.isObserver();
            },
        },

        watch: {
            editCast(value) {
                if (!value) {
                    this.getProgramEditionData(true);
                }
            },
        },
    };
</script>

<style lang="scss">
.info-summary-conatiner {
    @media screen and (max-width: 576px) {
        flex-direction: column;
        .monitoring-program-info-box, .monitoring-program-summary {
            width: 100% !important;
        }
    }
}
</style>
