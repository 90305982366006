<template>
    <FormObservationsTemplate ref="observationsTemplate" geobjectType="punkt" v-model:geobject="point" :geobjects="points"
                              v-model:additionalInformation="internalForm" additionalInformationInMain>
        <template v-slot:mainData>
            <div class="flex column-gap-2 flex-direction-column-700">
                <CustomCalendar label="Data liczenia" name="controlDate" required :showErrors="showErrorMessages"
                                class="w-4 max-width" v-model="internalForm.controlDate" :disabled="!editing"
                                :customErrorMessage="getDateErrorMessage(internalForm.controlDate)" />
                <CustomTimePicker label="Czas rozpoczęcia kontroli" name="startTime" required class="w-4 max-width"
                                  v-model="internalForm.startTime" :disabled="!editing"
                                  :showErrors="showErrorMessages" :customErrorMessage="getTimeErrorMessage([1,4])"/>
                <CustomTimePicker label="Czas zakończenia kontroli" name="endTime" required class="w-4 max-width"
                                  v-model="internalForm.endTime" :disabled="!editing"
                                  :showErrors="showErrorMessages" :customErrorMessage="getTimeErrorMessage([3,4])"/>
            </div>
        </template>
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition header="Punkty" v-model="point" :positions="points" :field="field"
                                        v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <div class="flex column-gap-2 flex-direction-column-1000">
                <CustomTimePicker label="Czas rozpoczęcia liczenia" required :showErrors="showErrorMessages"
                                  v-model="point.pointStartTime" name="startTime" class="w-3 max-width"
                                  :disabled="!editing" :customErrorMessage="getTimeErrorMessage([1,2])"/>
                <CustomTimePicker label="Czas zakończenia liczenia" required :showErrors="showErrorMessages"
                                  v-model="point.pointEndTime" name="endTime" class="w-3 max-width"
                                  :disabled="!editing" :customErrorMessage="getTimeErrorMessage([2,3])"/>
            </div>
        </template>
        <template v-slot:birdsData>
            <div class="flex column-gap-2 mb-4 flex-direction-column-1000">
                <CustomInputNumber name="magpieNests" label="Liczba gniazd sroki" class="w-3 max-width" v-model="point.magpieNests"
                                   required :showErrors="showErrorMessages" :disabled="!editing" />
                <CustomInputNumber name="crowNests" label="Liczba gniazd wrony" class="w-3 max-width" v-model="point.crowNests"
                                   required :showErrors="showErrorMessages" :disabled="!editing" />
            </div>
            <FormBirds v-model:noBirds="point.noBirds" v-model:birds="point.birds"
                       :minWidth="800" />
        </template>
        <template v-slot:habitatData>
            <FormMPPMHabitatData v-model:form="internalForm" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import CustomTimePicker from "@/components/form/CustomTimePicker";
    import CustomCalendar from "@/components/form/CustomCalendar";
    import FormBirds from "@/components/formBirds/FormBirds.vue";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import FormMPPMHabitatData from "./FormMPPMHabitatData.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import {DateUtils} from "@/utils/DateUtils";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMPPMObservations",

        components: {
            FormObservationsTemplate,
            CustomTimePicker,
            CustomCalendar,
            FormBirds,
            ObservationsSelectPosition,
            FormMPPMHabitatData,
            CustomInputNumber,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
            step: {
                type: Number,
                required: true,
            },
            field: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                points: [],
                activeIndex: 0,
                index: 0,
                point: _.cloneDeep(EmptyStructures.getEmptyMPPMPoint()),
                habitatDataOnActivePoint: null,
            };
        },

        emits: ["update:step", "update:form"],

        inject: ["showErrorMessages", "editing", "controlTimeFrame"],

        beforeMount() {
            this.points = this.internalForm.points.filter((p) => (p.notAudited === null || !p.notAudited));
            this.habitatDataOnActivePoint = this.point.habitatData;
            this.point = this.internalForm.points[0];
        },

        methods: {
            getTimeErrorMessage(inequalities) {
                for (let i = 0; i < inequalities.length; i += 1) {
                    if (inequalities[i] === 1 && DateUtils.isTimeEarlier(this.point.pointStartTime, this.form.startTime)) { // check startTime <= pointStartTime
                        return "Czas rozpoczęcia liczenia nie może być wcześniejszy niż czas rozpoczęcia kontroli";
                    }
                    if (inequalities[i] === 2 && DateUtils.isTimeEarlier(this.point.pointEndTime, this.point.pointStartTime)) { // check pointStartTime <= pointEndTime
                        return "Czas zakończenia liczenia nie może być wcześniejszy niż czas rozpoczęcia liczenia";
                    }
                    if (inequalities[i] === 3 && DateUtils.isTimeEarlier(this.form.endTime, this.point.pointEndTime)) { // check pointEndTime <= endTime
                        return "Czas zakończenia kontroli nie może być wcześniejszy niż czas zakończenia liczenia";
                    }
                    if (inequalities[i] === 4 && DateUtils.isTimeEarlier(this.form.endTime, this.form.startTime)) { // check startTime <= endTime
                        return "Czas zakończenia kontroli nie może być wcześniejszy niż czas rozpoczęcia kontroli";
                    }
                }
                return "";
            },
            getDateErrorMessage(value) {
                if (value && !ValidateUtils.validateIsDateInRange(value, this.controlTimeFrame.controlStart, this.controlTimeFrame.controlEnd)) {
                    return "Data musi być z przedziału " + this.controlTimeFrame.controlStart + " - "
                        + this.controlTimeFrame.controlEnd;
                }
                return "";
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            activeIndex(value) {
                console.log(this.internalForm);
                this.habitatDataOnActivePoint = this.internalForm.habitatData[value];
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/theme/mytheme/variables";
    @media screen and (max-width: 700px) {
        .flex-direction-column-700 {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .flex-direction-column-1000 {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
