<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMPGHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex column-gap-3 flex-direction-column">
                <CustomSelectOne label="Położenie" name="location" v-model="slotProps.habitatData.location" class="w-4 max-width"
                                 :items="dicts.location" :disabled="slotProps.disabled"
                                 @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                <CustomSelectOne label="Rzeźba terenu" name="landform" v-model="slotProps.habitatData.landform"
                                 :items="dicts.landform" class="w-4 max-width" :disabled="slotProps.disabled"
                                 @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                <CustomSelectOne label="Ekspozycja stoku" name="direction" :items="dicts.direction" class="w-4 max-width"
                                 v-model="slotProps.habitatData.direction"
                                 :disabled="slotProps.disabled"
                                 @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
            </div>
            <div class="flex column-gap-3 flex-direction-column">
                <CustomSelectOne label="Wilgotność gruntu" name="water" :items="dicts.water"
                                 v-model="slotProps.habitatData.water"
                                 class="w-4 max-width" :disabled="slotProps.disabled"
                                 @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                <CustomInputNumber label="Wysokość roślinności" name="plantHeight"
                                   v-model="slotProps.habitatData.plantHeight" class="w-4 max-width"
                                   :disabled="slotProps.disabled"
                                   @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                <CustomInputNumber label="% pokrycia roślinnością trawiastą" name="grassCovered"
                                   v-model="slotProps.habitatData.grassCovered" class="w-4 max-width" :disabled="slotProps.disabled"
                                   @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                   :min="0" :max="100"/>
            </div>
            <div class="flex column-gap-3 flex-direction-column">
                <CustomInputNumber label="% pokrycia bylinami i krzewinkami" name="perennialsCovered"
                                   v-model="slotProps.habitatData.perennialsCovered" class="w-4 max-width"
                                   :disabled="slotProps.disabled"
                                   @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                   :min="0" :max="100"/>
                <CustomInputNumber label="% pokrycia krzewami (w tym kosodrzewiną)" name="shrubsCovered"
                                   v-model="slotProps.habitatData.shrubsCovered" class="w-4 max-width" :disabled="slotProps.disabled"
                                   @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                   :min="0" :max="100" longLabel/>
                <CustomInputNumber label="% powierzchni skalistej" name="rockSurface"
                                   v-model="slotProps.habitatData.rockSurface" class="w-4 max-width" :disabled="slotProps.disabled"
                                   @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"
                                   :min="0" :max="100"/>
            </div>
            <div class="flex column-gap-3 flex-direction-column pr-5">
                <CustomInputNumber label="Liczba drzew" name="treeNumber" v-model="slotProps.habitatData.treeNumber"
                                   class="w-4 max-width" :disabled="slotProps.disabled"
                                   @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
                <CustomCheckbox label="Obecność potoków/zbiorników wodnych" name="waterPresence" class="w-4 max-width"
                                v-model="slotProps.habitatData.waterPresence" :disabled="slotProps.disabled"
                                @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
            </div>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import _ from "lodash";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";

    export default {
        name: "HabitatDataMPG",

        components: {
            HabitatDataTemplate,
            CustomInputNumber,
            CustomSelectOne,
            CustomCheckbox,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        data() {
            return {
                emptyHabitatData: _.cloneDeep(EmptyStructures.getEmptyMPGHabitatData()),
            };
        },

        inject: ["dicts", "showErrorMessages"],

        emits: ["update:habitatDataToChange"],

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 1015px) {
        .flex-direction-column {
            flex-direction: column;
            padding-right: 0 !important;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
