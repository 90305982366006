<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMSLHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex column-gap-3 flex-direction-column">
                <CustomMultiSelect label="Rodzaj siedliska" name="habitatType"
                                   v-model="slotProps.habitatData.habitatType" class="w-6 max-width" :items="dicts.habitatType"
                                   :showErrors="showErrorMessages" :emptyValue="false"
                                   :disabled="!editing"
                                   @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)" />
                <CustomInputTextArea v-if="computedCondition(slotProps.habitatData.habitatType)" label="Opis innych" class="w-6 max-width" name="habitatNotes"
                                     v-model="slotProps.habitatData.habitatNotes" :disabled="!editing"
                                     @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)" />
            </div>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";

    export default {
        name: "HabitatDataMSL",

        components: {
            HabitatDataTemplate,
            CustomMultiSelect,
            CustomInputTextArea,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:habitatDataToChange"],

        methods: {
            computedCondition(habitatTypeList) {
                if (habitatTypeList && habitatTypeList.length > 0) {
                    return habitatTypeList.some((hT) => hT.code === "INNE");
                }
                return false;
            },
        },

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 700px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
