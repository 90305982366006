<template>
    <div>
        <div class="flex column-gap-4 flex-direction-column">
            <CustomCalendar label="Data liczenia" class="flex-1 w-6 max-width" required :showErrors="showErrorMessages"
                            v-model="localControl.controlDate" name="controlDate" :disabled="!editing"
                            :customErrorMessage="getDateErrorMessage(localControl.controlDate,index)" />
            <CustomSelectOne label="Wynik kontroli" :items="dicts.nestingCriteria"
                             class="flex-1 w-6 max-width" required v-model="localControl.nestingCriteria"
                             name="nestingCriteria" :showErrors="showErrorMessages" :disabled="!editing"
                             tooltip/>
            <CustomSelectOne v-if="secondControl && controlHappened" label="Wynik lęgu" class="flex-1 w-6 max-width"
                             :items="dicts.breedingResult" :showErrors="showErrorMessages" :disabled="!editing"
                             required v-model="localControl.breedingResult" name="breedingResult" />
        </div>
        <div v-if="secondControl">
            <div class="flex column-gap-4 flex-direction-column" v-if="breedingSuccess">
                <CustomSelectOne label="Metoda kontroli liczby młodych" :items="dicts.controlMethod" required
                                 class="flex-1 w-0 max-width" :showErrors="showErrorMessages" :disabled="!editing"
                                 v-model="localControl.controlMethod" name="controlMethod"/>
                <CustomInputNumber label="Liczba piskląt" class="flex-1 w-0 max-width" required
                                   :showErrors="showErrorMessages" :disabled="!editing"
                                   v-model="localControl.nestlings" name="nestlings"/>
            </div>
            <div class="flex column-gap-4 flex-direction-column" v-else-if="breedingLosses">
                <CustomSelectOne label="Stadium strat" :items="dicts.lossesStage"
                                 :showErrors="showErrorMessages" :disabled="!editing"
                                 class="flex-1 w-0 max-width" required v-model="localControl.lossesStage" name="lossesStage"/>
                <CustomSelectOne v-if="knownLossesStage" label="Rozmiar strat" :items="lossesSizeItems"
                                 itemValue="value" class="flex-1 w-0 max-width" :disabled="!editing"
                                 v-model="localControl.entireBroodLost" name="lossesSize" />
                <CustomSelectOne label="Okoliczności strat" class="flex-1 w-0 max-width"
                                 :items="dicts.lossesCircumstances" v-model="localControl.lossesCircumstances"
                                 name="lossesCircumstances" :disabled="!editing"/>
            </div>
        </div>
        <div class="flex flex-column">
            <HeaderComponent headerText="Aktywność ludzka przy gnieździe" class="mt-3 mb-5" />
            <div class="flex flex-column gap-2">
                <div class="flex column-gap-4 flex-direction-column">
                    <CustomCheckbox label="Stwierdzono aktywność" name="activityDetected"
                                    v-model="activityDetected" :disabled="!editing"/>
                    <CustomSelectOne v-if="activityDetected" label="Rodzaj aktywności (do 100 metrów od gniazda)"
                                     :items="dicts.humanActivity" :disabled="!editing"
                                     class="flex-1 w-0 max-width" required
                                     v-model="localControl.humanActivity" name="activityType"
                                     longLabelSelect />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderComponent from "@/components/HeaderComponent.vue";
    import CustomCalendar from "@/components/form/CustomCalendar";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "SingleControlMGR",

        components: {
            HeaderComponent,
            CustomCalendar,
            CustomSelectOne,
            CustomInputNumber,
            CustomCheckbox,
        },

        props: {
            control: {
                type: Object,
                required: true,
            },
            secondControl: {
                type: Boolean,
                default: false,
            },
            index: {
                type: Number,
                default: 0,
            },
        },

        inject: ["showErrorMessages", "dicts", "editing", "controlTimeFrame"],

        emits: ["update:control"],

        data() {
            return {
                lossesSizeItems: [
                    {value: true, label: "Cały lęg"},
                    {value: false, label: "Część lęgu"},
                ],
                activityDetected: false,
            };
        },

        beforeMount() {
            if (this.localControl.humanActivity !== null) {
                this.activityDetected = true;
            }
        },

        methods: {
            getDateErrorMessage(value, i) {
                if (value && !ValidateUtils.validateIsDateInRange(value, this.dicts.controlType[i]?.controlStart, this.dicts.controlType[i]?.controlEnd)) {
                    return "Data musi być z przedziału " + this.dicts.controlType[i].controlStart + " - "
                        + this.dicts.controlType[i].controlEnd;
                }
                return "";
            },
        },

        computed: {
            localControl: {
                get() { return this.control; },
                set(value) { this.$emit("update:control", value); },
            },
            controlHappened() {
                return this.localControl.nestingCriteria != null
                    && this.localControl.nestingCriteria?.key !== "MGR1_BRAK";
            },
            breedingSuccess() {
                return this.localControl.breedingResult != null
                    && this.localControl.breedingResult?.key === "SUCCESS";
            },
            breedingLosses() {
                return this.localControl.breedingResult != null
                    && this.localControl.breedingResult?.key === "LOSSES";
            },
            knownLossesStage() {
                return this.localControl.lossesStage != null
                    && this.localControl.lossesStage?.key !== "UNKNOWN";
            },
        },

        watch: {
            localControl: {
                handler(value) { this.$emit("update:control", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 800px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
