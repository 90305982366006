<template>
    <div class="observations-control-table overflow-x-auto">
        <DataTable :value="internalBirds" v-model:editingRows="editingRows" editMode="row" dataKey="ordinal"
                   @row-edit-save="onRowEditSave"
                   size="small" :rowClass="() => 'rowWidth'" class="navigate-table" @click="arrowsNavigateTable"
                   style="min-width: 1440px;">
            <template #header>
                <AddBirdsMCH v-model="internalBirds"
                             :error="showError()" style="margin-left: 4.1666%" />
            </template>
            <Column header="Lp." field="ordinal" style="width: 4.1666%;"/>
            <Column header="Gatunek" class="w-2" field="species">
                <template #editor="{data,field}">
                    <CustomSelectOne v-model="data[field]" name="species"
                                     itemLabel="name" :items="dicts.programBirds" />
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.species?.name ? slotProps.data.species?.name : slotProps.data.species?.namePl }}
                </template>
            </Column>
            <Column header="Kontrola" field="controlType" class="w-1" >
                <template #editor="{data,field}">
                    <CustomSelectOne v-model="data[field]" :items="dicts.controlType" name="controlType" />
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.controlType ? slotProps.data.controlType.label : "" }}
                </template>
            </Column>
            <Column header="Metoda liczenia" field="countingMethod" class=w-2>
                <template #editor="{data,field}">
                    <CustomSelectOne v-model="data[field]" :items="dicts.countingMethod" name="countingMethod" />
                </template>
                <template #body="slotProps">
                    {{ slotProps.data.countingMethod ? slotProps.data.countingMethod.label : "" }}
                </template>
            </Column>
            <Column header="L. gniazd" field="nests" class="w-1">
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="nestsNumber"
                                       :disabled="data.countingMethod?.code === '4' || data.countingMethod?.code === '5'"
                                       :min="1" />
                </template>
            </Column>
            <Column header="L. dorosłych os." field="adults" class="w-1">
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="adultsNumber" :min="1"
                                       :disabled="computedDisabled(data,field)" />
                </template>
            </Column>
            <Column header="L. par" field="pairs" class="w-1">
                <template #editor="{data,field}">
                    <CustomInputNumber v-model="data[field]" name="nestlingsNumber" :min="1"
                                       :disabled="computedDisabled(data,field)" />
                </template>
            </Column>
            <Column header="Kryteria lęgowości" field="nestingCriteria" class="w-2">
                <template #editor="{data,field}">
                    <CustomMultiSelect label="Kryteria lęgowości" v-model="data[field]" :disabled="!editing"
                                       :name="`nestingCriteria-${index}`" :items="dicts.nestingCriteria"
                                       style="max-width: 300px;" />
                </template>
                <template #body="slotProps">
                    <div class="flex flex-wrap gap-1">
                        <div v-for="nc in slotProps.data.nestingCriteria" v-tooltip.top="nc.label" :key="nc.code">
                            {{ nc.code }};
                        </div>
                    </div>
                </template>
            </Column>
            <Column v-if="editing" :rowEditor="true" style="width: 120px; text-align: center;"/>
            <Column v-if="editing" style="width: 60px; text-align: center;">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteBirdObserv(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import _ from "lodash";
    import {ref} from "vue";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Tooltip from "primevue/tooltip";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomButton from "@/components/form/CustomButton.vue";
    import AddBirdsMCH from "./AddBirdsMCH.vue";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";
    import TableUtils from "@/utils/TableUtils";

    export default {
        name: "FormBirdsMCH",

        props: {
            birds: {
                type: Array,
                default: () => [],
            },
            // index: null,
        },

        components: {
            DataTable,
            Column,
            // Tooltip,
            CustomSelectOne,
            CustomInputNumber,
            CustomButton,
            AddBirdsMCH,
            CustomMultiSelect,
        },

        directives: {
            tooltip: Tooltip,
        },

        emits: ["update:birds"],

        inject: ["programCode", "dicts", "editing", "showErrorMessages"],

        data() {
            return {
                editingRows: ref([]),
                showMoreText: [],
                species: [],
            };
        },

        // beforeMount() {
        //     for (let i = 0; i < this.internalBirds.length; i += 1) {
        //         this.internalBirds[i].ordinal = i + 1;
        //         this.internalBirds[i].controlType.controlStart = null;
        //         this.internalBirds[i].controlType.controlEnd = null;
        //         this.internalBirds[i].controlType.disabled = false;
        //     }
        // },

        methods: {
            onRowEditSave(event) {
                const {newData, index} = event;

                this.internalBirds[index] = newData;

                if (newData.countingMethod?.code === "4" || newData.countingMethod?.code === "5") {
                    this.internalBirds[index].nests = null;
                }

                if (newData.species?.code === "CHLHYB"
                    || newData.species?.code === "CHLNIG"
                    || newData.species?.code === "CHLLEU") {
                    this.internalBirds[index].adults = null;
                }

                if (newData.species?.code === "PODTUS"
                    || newData.species?.code === "PODENA"
                    || newData.species?.code === "PODNIG"
                    || newData.species?.code === "TACRUF") {
                    this.internalBirds[index].pairs = null;
                }
            },
            deleteBirdObserv(birdObserv) {
                const index = this.internalBirds.indexOf(birdObserv);
                this.internalBirds.splice(index, 1);
                if (this.showMoreText.length > 0) {
                    for (let i = 0; i < this.showMoreText.length; i += 1) {
                        this.showMoreText[i] = false;
                    }
                }
                for (let i = 0; i < this.internalBirds.length; i += 1) {
                    this.internalBirds[i].ordinal = i + 1;
                }
            },
            textTruncate(text) {
                if (text.length > 60) {
                    return _.truncate(text, {
                        length: 60,
                        separator: " ",
                    });
                }
                return text;
            },
            showMore(index) {
                if (this.showMoreText[index]) {
                    this.showMoreText[index] = false;
                } else {
                    this.showMoreText[index] = true;
                }
            },
            tooltipPosition(e, i, length) {
                if (length > 60) {
                    const x = e.clientX;
                    const y = e.clientY;
                    document.getElementById(`nesting-criteria-tooltip-${i}`).style.left = x + 20 + "px";
                    document.getElementById(`nesting-criteria-tooltip-${i}`).style.top = y + 10 + "px";
                }
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            computedDisabled(data, field) {
                if (field === "adults") {
                    if (data.species?.code === "CHLHYB"
                        || data.species?.code === "CHLNIG"
                        || data.species?.code === "CHLLEU") {
                        return true;
                    }
                }
                if (field === "pairs") {
                    if (data.species?.code === "PODTUS"
                        || data.species?.code === "PODENA"
                        || data.species?.code === "PODNIG"
                        || data.species?.code === "TACRUF") {
                        return true;
                    }
                }
                return false;
            },
        },

        computed: {
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
            birds: {
                handler(value) {
                    TableUtils.addOrdinal(value);
                },
                immediate: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .nesting-criteria-text {
        position: relative;
        .show-tooltip, .hide-tooltip {
            display: none;
            position: fixed;
            width: max-content;
            padding: 2px 4px;
            border: 1px solid black;
            background-color: var(--surface-a);
            border-radius: 4px;
        }
        &:hover {
            .show-tooltip, .hide-tooltip {
                display: block;
            }
        }
    }
</style>
