<template>
    <CustomCheckbox label="Brak ptaków" v-model="internalNoBirds" :disabled="!editing"
                    name="noBirds" :error="showError()"/>
    <div class="overflow-x-auto">
        <DataTable v-if="!internalNoBirds" :value="internalBirds" v-model:editingRows="editingRows" editMode="row"
                   dataKey="ordinal" @row-edit-save="onRowEditSave" size="small" :rowClass="() => 'rowWidth'" class="navigate-table" @click="arrowsNavigateTable"
                   style="min-width: 1200px;">
            <template #header>
                <AddBirdsMLS v-model="internalBirds" :error="showError()" />
            </template>
            <Column header="Lp." field="ordinal" style="width: 4.1666%;" />
            <Column header="Kod" class="w-1" field="species">
                <template #body="slotProps">
                    {{ slotProps.data.species?.code }}
                </template>
                <template #editor="{data,field}">
                    <CustomOfflineAutocomplete label="Kod" name="speciesCode" v-model="data[field]"
                                               :items="dicts.programBirds" optionLabel="code" searchField="code" />
                </template>
            </Column>
            <Column header="Gatunek" class="w-1" field="species">
                <template #body="slotProps">
                    {{ slotProps.data.species?.namePl ? slotProps.data.species?.namePl : slotProps.data.species?.name }}
                </template>
                <template #editor="{data,field}">
                    <CustomOfflineAutocomplete label="Gatunek" name="speciesName" v-model="data[field]"
                                               :items="dicts.programBirds" optionLabel="name" searchField="name" />
                </template>
            </Column>
            <Column header="Liczba osobników" class="w-2" field="count">
                <template #editor="{data,field}">
                    <CustomInputNumber label="Liczba osobników" name="count" v-model="data[field]" :min="1" />
                </template>
            </Column>
            <Column header="Kategoria odległości" class="w-2" field="distance">
                <template #body="slotProps">
                    {{ slotProps.data.distance?.label }}
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne label="Kategoria odległości" name="distance" v-model="data[field]"
                                     :items="dicts.distance"/>
                </template>
            </Column>
            <Column header="Kryterium lęgowości" class="w-3" field="nestingCriteria">
                <template #body="slotProps">
                    <div v-tooltip.top="{value: slotProps.data.nestingCriteria.label,class: 'tooltip-style'}">
                        {{ getDictLabelWithCodePrefix(slotProps.data.nestingCriteria) }}
                    </div>
                </template>
                <template #editor="{data,field}">
                    <CustomSelectOne label="Kryterium lęgowości" name="nestingCriteria" v-model="data[field]"
                                     :items="dicts.nestingCriteria" :itemLabel="getDictLabelWithCodePrefix"
                                     short tooltip/>
                </template>
            </Column>
            <Column class="w-1"/>
            <Column v-if="editing" :rowEditor="true" style="text-align: center;" class="w-1"/>
            <Column v-if="editing" style="width: 4.1666%; text-align: center;">
                <template #body="slotProps">
                    <CustomButton icon="pi pi-trash" borderColor="transparent"
                                  @click="deleteBirdObservation(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import _ from "lodash";
    import {ref} from "vue";
    import Tooltip from "primevue/tooltip";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomButton from "@/components/form/CustomButton.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import TableUtils from "@/utils/TableUtils";
    import CustomOfflineAutocomplete from "@/components/form/inner/CustomOfflineAutocomplete.vue";
    import AddBirdsMLS from "@/views/formMLS/components/AddBirdsMLS.vue";
    import TableArrowsNavigation from "@/utils/TableArrowsNavigation";

    export default {
        name: "FormBirdsMLS",

        components: {
            AddBirdsMLS,
            CustomOfflineAutocomplete,
            DataTable,
            Column,
            CustomInputNumber,
            CustomSelectOne,
            CustomCheckbox,
            CustomButton,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            noBirds: {
                type: Boolean,
            },
            birds: {
                type: Array,
                default: () => [],
            },
        },

        inject: ["editing", "showErrorMessages", "dicts"],

        emits: ["update:noBirds", "update:birds"],

        data() {
            return {
                editingRows: ref([]),
            };
        },

        methods: {
            arrowsNavigateTable(ctx) {
                TableArrowsNavigation.arrowsNavigateTable(ctx);
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.internalBirds[index] = newData;
            },
            deleteBirdObservation(item) {
                const index = this.internalBirds.indexOf(item);
                this.internalBirds.splice(index, 1);
            },
            showError() {
                return this.showErrorMessages && ((this.internalBirds == null
                    || this.internalBirds.length === 0) && !(this.internalNoBirds === true));
            },
            getDictLabelWithCodePrefix(item) {
                if (item == null) return "";
                return this.textTruncate(item.code + " - " + item.label);
            },
            textTruncate(text) {
                if (text.length > 40) {
                    return _.truncate(text, {
                        length: 40,
                        separator: " ",
                    });
                }
                return text;
            },
        },

        computed: {
            internalNoBirds: {
                get() { return this.noBirds; },
                set(value) { this.$emit("update:noBirds", value); },
            },
            internalBirds: {
                get() { return this.birds; },
                set(value) { this.$emit("update:birds", value); },
            },
        },

        watch: {
            internalBirds: {
                handler(value) { this.$emit("update:birds", value); },
                deep: true,
            },
            birds: {
                handler(value) {
                    TableUtils.addOrdinal(value);
                },
                immediate: true,
            },
        },
    };
</script>

<style lang="scss">
    .tooltip-style {
        max-width: 300px;
    }
</style>
