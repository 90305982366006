<template>
    <div class="custom-autocomplete field relative">
        <span class="p-float-label" :style="computedWidth()">
            <AutoComplete :id="name" v-model="internalValue" :optionLabel="optionLabel" :suggestions="filteredItems"
                          @complete="search" :dropdown="dropdown" :multiple="multiple"
                          :placeholder="internalValue ? '' : label" :disabled="disabled" :class="computedClasses" completeOnFocus/>
            <CustomLabel :name="name" :label="label" :required="required" :disabled="disabled"
                         :class="{'label-filled': internalValue != null && internalValue !== ''}" />
        </span>
        <CustomValidationMessage :errorMessage="customErrorMessage" />
        <CustomValidationMessage v-if="showRequiredError" :errorMessage="$t('message.validations.required')" />
    </div>
</template>

<script>
    import AutoComplete from "primevue/autocomplete";
    import CustomValidationMessage from "@/components/form/inner/CustomValidationMessage.vue";
    import CustomLabel from "@/components/form/inner/CustomLabel.vue";

    export default {
        name: "CustomOfflineAutocomplete",

        components: {
            CustomLabel,
            CustomValidationMessage,
            AutoComplete,
        },

        props: {
            items: {
                type: Array,
                default: () => [],
            },
            optionLabel: {
                default: "label",
            },
            searchField: {
                type: String,
                default: "label",
            },
            modelValue: {
                required: true,
            },
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            dropdown: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            customErrorMessage: {
                type: String,
                default: "",
            },
            showErrors: {
                type: Boolean,
                default: false,
            },
            required: {
                type: Boolean,
                default: false,
            },
            width: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                filteredItems: [],
            };
        },

        emits: ["update:modelValue"],

        methods: {
            search(event) {
                if (!event.query.trim().length) {
                    this.filteredItems = [...this.items];
                } else {
                    this.filteredItems = this.items.filter(
                        (item) => item[this.searchField].toLowerCase().startsWith(event.query.toLowerCase()));
                }
            },
            computedWidth() {
                if (this.width === null) return "";
                return "width: " + this.width;
            },
        },

        computed: {
            internalValue: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
            showRequiredError() {
                return this.showErrors && (this.internalValue == null || this.internalValue === "");
            },
            computedClasses() {
                return (this.customErrorMessage !== "" || this.showRequiredError) ? "p-invalid" : "";
            },
        },
    };
</script>

<style lang="less" scoped>
.custom-autocomplete {
    .p-autocomplete {
        width: 100%;
    }
}
</style>
