<template>
    <label class="mb-2 inline-block custom-label" :class="computedClass()" :for="name">
        {{ computedLabel }}
    </label>
</template>

<script>
    export default {
        name: "CustomLabel",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            longLabel: {
                type: Boolean,
                default: false,
            },
            longLabelSelect: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            computedLabel() {
                if (this.label === undefined) return "";
                return this.label + (this.required ? "*" : "");
            },
        },

        methods: {
            computedClass() {
                let computedClass = "";
                if (this.disabled) computedClass += "disabled ";
                if (this.longLabel) computedClass += "long-label ";
                if (this.longLabelSelect) computedClass += "long-label-select ";
                return computedClass;
            },
        },
    };

</script>

<style lang="scss">
    // @import "../assets/theme/mytheme/variables";
    .custom-label {
        width: max-content;
        &.disabled {
            color: var(--text-color-disabled);
        }
    }
    .long-label, .long-label-select {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 1.2rem;
        max-width: fit-content;
    }
    .long-label {
        width: 95%;
    }
    .long-label-select {
        width: 84%;
    }
</style>
