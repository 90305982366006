<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:geobject="position" :geobjects="positions"
                              v-model:additionalInformation="position">
        <template v-slot:selectGeobject>
            <ObservationsSelectPosition v-model="position" :positions="positions" :field="field" v-model:step="internalStep"/>
        </template>
        <template v-slot:controlData>
            <ControlTypeAndDates v-model="position" />
            <div class="flex column-gap-2 flex-direction-column">
                <CustomCheckbox class="w-4 max-width" label="Gniazda śmieszki (zaznacz jeżeli były)"
                                v-model="position.smieszkaNest" name="smieszkaNest" :disabled="!editing" />
                <CustomSelectOne class="w-4 max-width" label="Typ siedliska" v-model="position.habitatType" name="habitatType"
                                 :items="dicts.habitatType" required :showErrors="showErrorMessages"
                                 :disabled="!editing" />
                <div class="dummy w-4"></div>
            </div>
        </template>
        <template v-slot:birdsData>
            <FormBirdsMPWR v-model:birds="position.birds" v-model:noBirds="position.noBirds"/>
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import _ from "lodash";
    import ObservationsSelectPosition from "@/components/formSharedComponents/ObservationsSelectPosition.vue";
    import {EmptyStructures} from "@/utils/EmptyStructures";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import FormBirdsMPWR from "./FormBirdsMPWR";

    export default {
        name: "FormMPWRObservations",

        components: {
            ObservationsSelectPosition,
            ControlTypeAndDates,
            FormObservationsTemplate,
            CustomSelectOne,
            CustomCheckbox,
            FormBirdsMPWR,
        },

        props: {
            allPositions: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            step: {
                type: Number,
                required: true,
            },
            form: {
                type: Object,
                required: true,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        data() {
            return {
                positions: [],
                position: _.cloneDeep(EmptyStructures.getEmptyMPWRPosition()),
            };
        },

        emits: ["update:step", "update:form"],

        beforeMount() {
            this.positions = this.allPositions.filter((p) => (p.notAudited === null || !p.notAudited));
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
            internalStep: {
                get() { return this.step; },
                set(value) { this.$emit("update:step", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            position: {
                handler(value) {
                    if (!value.birds) {
                        value.birds = [];
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 690px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
