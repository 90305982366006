<template>
    <Dialog class="similar-position-dialog" header="Istnieje podobne stanowisko!"
            v-model:visible="show" :draggable="false" @hide="hide">
        <div class="similar-position-dialog-container m-7">
            <p class="mb-5 similar-position-dialog-text">
                W pobliżu stanowiska, które dodałeś, istnieje już inne stanowisko. Czy chcesz dodać nowe?
            </p>
            <div class="flex gap-8 similar-position-dialog-content">
                <div class="similar-position-dialog-content__left">
                    <div class="your-position">
                        <p class="position-header">Stanowisko, które dodajesz:</p>
                        <p class="uppercase position-value">
                            {{ newAddedPos !== null ? newAddedPos.name : "" }}
                        </p>
                    </div>
                    <div class="similar-archive-position">
                        <p class="position-header">Podobne stanowisko archiwalne:</p>
                        <p class="uppercase position-value">
                            {{ similarPos !== null ? similarPos.name : "" }}
                        </p>
                    </div>
                </div>
                <div class="similar-position-dialog-content__right">
                    <div class="similar-positions-map" style="height: 400px; width: 400px;">
                        <MapMultiMarkersSimple mapId="similarPositionMap" height="400px" :zoomControls="false"
                                               :positions="positions" :isOn="false" />
                    </div>
                </div>
            </div>
            <div class="flex justify-content-around mt-7 flex-wrap gap-2">
                <CustomButton label="Użyj stanowiska archiwalnego" color="var(--surface-a)"
                              bgColor="var(--secondary-color)" @click="useArchive" />
                <CustomButton label="Dodaj nowe stanowisko" color="var(--surface-a)" bgColor="#EA3030"
                              @click="addNew" />
            </div>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import CustomButton from "@/components/form/CustomButton.vue";
    import MapMultiMarkersSimple from "@/components/map/MapMultiMarkersSimple.vue";
    import {assignPosition, addPosition} from "@/swagger/vue-api-client";

    export default {
        name: "SimilarPositionDialog",

        components: {
            Dialog,
            CustomButton,
            MapMultiMarkersSimple,
        },

        props: {
            showSimilar: {
                type: Boolean,
                default: false,
            },
            newAddedPos: null,
            similarPos: null,
            fieldID: null,
            programCode: null,
        },

        emits: ["hideSimilar", "add"],

        data() {
            return {
                show: false,
                positions: [],
            };
        },

        watch: {
            showSimilar() {
                this.show = this.showSimilar;
            },
            newAddedPos() {
                this.positions.push({
                    name: this.newAddedPos.name,
                    positionId: this.newAddedPos.positionId,
                    coordinates: {
                        longitude: this.newAddedPos.coordinates.longitude,
                        latitude: this.newAddedPos.coordinates.latitude,
                    },
                    status: "ADDED",
                });
            },
            similarPos() {
                this.positions.push({
                    name: this.similarPos.name,
                    positionId: this.similarPos.positionId,
                    coordinates: {
                        longitude: this.similarPos.coordinates.longitude,
                        latitude: this.similarPos.coordinates.latitude,
                    },
                    status: "SIMILAR",
                });
            },
        },

        methods: {
            useArchive() {
                assignPosition({
                    programCode: this.programCode,
                    formId: this.$route.params.id,
                    positionId: this.similarPos.positionId,
                });
                this.$emit("hideSimilar", false);
            },
            addNew() {
                addPosition({
                    programCode: this.programCode,
                    formId: this.$route.params.id,
                    body: {
                        fieldId: this.fieldID,
                        name: this.newAddedPos.name,
                        coordinates: this.newAddedPos.coordinates,
                    },
                    force: true,
                })
                    .then((response) => {
                        this.$emit("add", {...this.newPosition, positionId: response.data.positionId});
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się dodać stanowiska, spróbuj ponownie.",
                            life: 3000,
                        });
                    });
                this.$emit("hideSimilar", false);
            },
            hide() {
                this.$emit("hideSimilar", false);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/theme/mytheme/variables";
    @import "../../../views/assets/scss/dialogs";
    .similar-position-dialog-content {
        @media screen and (max-width: 1000px) {
            flex-direction: column;
            align-items: center;
            .similar-positions-map {
                width: 80vw !important;
            }
        }
    }
</style>
