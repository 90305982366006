<template>
    <div class="flex flex-row column-gap-8 flex-wrap">
        <div>
            <CustomCalendar label="Data liczenia" class="flex-1 w-0" v-model="internalForm.controlDate"
                            :disabled="!editing" name="controlDate"
                            :customErrorMessage="getDateErrorMessage(internalForm.controlDate)" required
                            style="min-width: 200px;" />
        </div>
        <div class="flex flex-column">
            <div class="flex flex-row gap-3 align-items-center mb-1" v-if="numberOfTransects >= 1">
                <div class="mb-3 w-2" v-if="numberOfTransects >= 2">Odcinki 1-5</div>
                <div class="flex flex-wrap column-gap-3">
                    <CustomTimePicker label="Godzina rozpoczęcia" class="flex-1 w-0" v-model="internalForm.startTime1"
                                      :disabled="!editing" :name="`controlStartTime-1`"
                                      :showErrors="showErrorMessages" required
                                      style="min-width: 200px;" />
                    <CustomTimePicker label="Godzina zakończenia" class="flex-1 w-0" v-model="internalForm.endTime1"
                                      :disabled="!editing" :name="`controlEndTime-1`" required
                                      :customErrorMessage="getTimeErrorMessage(internalForm.endTime1,
                                                                               showWrongTime1Message)"
                                      style="min-width: 200px;" />
                </div>
            </div>
            <div v-if="numberOfTransects >= 2" class="flex flex-row gap-3 align-items-center mb-1 relative" >
                <div class="mb-3 w-2">Odcinki 6-10</div>
                <div class="flex flex-wrap column-gap-3">
                    <CustomTimePicker label="Godzina rozpoczęcia" class="flex-1 w-0" v-model="internalForm.startTime2"
                                      :disabled="!editing || disabledTimeTwo" :name="`controlStartTime-2`"
                                      :showErrors="showErrorMessages" required
                                      style="min-width: 200px;" />
                    <CustomTimePicker label="Godzina zakończenia" class="flex-1 w-0" v-model="internalForm.endTime2"
                                      :disabled="!editing || disabledTimeTwo" :name="`controlEndTime-2`" required
                                      :customErrorMessage="getTimeErrorMessage(internalForm.endTime2,
                                                                               showWrongTime2Message)"
                                      style="min-width: 200px;" />
                </div>
            </div>
        </div>
        <CustomCheckbox v-if="numberOfTransects >= 2" label="Nie dotyczy" name="disabledTimeTwo"
                        v-model="disabledTimeTwo"
                        class="align-self-end mb-5" :disabled="!editing" />
    </div>
</template>

<script>
    import CustomTimePicker from "@/components/form/CustomTimePicker.vue";
    import CustomCalendar from "@/components/form/CustomCalendar.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";

    export default {
        name: "TransectControlDate",

        components: {
            CustomCalendar,
            CustomTimePicker,
            CustomCheckbox,
        },

        props: {
            form: {
                type: Object,
            },
            numberOfTransects: {
                type: Number,
                default: 1,
            },
        },

        inject: ["editing", "showErrorMessages"],

        data() {
            return {
                showWrongTime1Message: false,
                showWrongTime2Message: false,
                showWrongDateMessage: false,
                disabledTimeTwo: false,
            };
        },

        methods: {
            getRequiredMessage(value) {
                if (this.showErrorMessages && (value == null || value === "")) {
                    return this.$t("message.validations.required");
                }
                return "";
            },
            getTimeErrorMessage(value, show) {
                if (show && value !== null && value !== "") {
                    return this.$t("message.validations.afterTime");
                }
                return this.getRequiredMessage(value);
            },
            getDateErrorMessage(value) {
                if (this.showWrongDateMessage && value !== null
                    && (DateUtils.isDateEarlier(value, this.form.control.controlStart)
                        || DateUtils.isDateEarlier(this.form.control.controlEnd, value))) {
                    return "Data musi być z przedziału " + this.form.control.controlStart + " - "
                        + this.form.control.controlEnd;
                }
                return this.getRequiredMessage(value);
            },
        },

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
        },

        watch: {
            /* eslint-disable */
            "internalForm.startTime1"(value) {
                this.showWrongTime1Message = value != null && this.internalForm.endTime1 != null
                    && DateUtils.isTimeEarlier(this.internalForm.endTime1, value);
            },
            "internalForm.endTime1"(value) {
                this.showWrongTime1Message = value != null && this.internalForm.startTime1 != null
                    && DateUtils.isTimeEarlier(value, this.internalForm.startTime1);
            },
            "internalForm.startTime2"(value) {
                this.showWrongTime2Message = value != null && this.internalForm.endTime2 != null
                    && DateUtils.isTimeEarlier(this.internalForm.endTime2, value);
            },
            "internalForm.endTime2"(value) {
                this.showWrongTime2Message = value != null && this.internalForm.startTime2 != null
                    && DateUtils.isTimeEarlier(value, this.internalForm.startTime2);
            },
            "internalForm.controlDate"(value) {
                this.showWrongDateMessage = value != null
                    && (DateUtils.isDateEarlier(value,this.form.control.controlStart)
                    || DateUtils.isDateEarlier(this.form.control.controlEnd,value));
            },
            /* eslint-enable */
            internalForm: {
                handler(value) { this.$emit("update:form", value); },
                deep: true,
            },
            disabledTimeTwo(value) {
                if (value) {
                    this.internalForm.startTime2 = null;
                    this.internalForm.endTime2 = null;
                }
            },
        },
    };
</script>

<style lang="scss">
    .hide-second-transect-time {
        position: absolute;
        right: -125px;
        top: 25%;
    }
</style>
