<template>
    <div :id="mapId" :style="height ? `height: ${this.height}; position: relative;` : ''" class="map-multi-transects" />
    <div ref="popup" class="marker-popup hidden"></div>
    <div v-if="popup">
        <div v-for="(transect,index) in transects" ref="popup" class="marker-popup" :key="transect.id"
             @click="clickPopup(transect.id,index)"
             @mouseover="mouseoverOrFocusPopup(transect.id,index)"
             @focus="mouseoverOrFocusPopup(transect.id,index)">
            <slot name="popup" :transect="transect" :index="index"></slot>
        </div>
    </div>
    <div v-if="popupFull">
        <div v-for="(transect,index) in transects" ref="popupFull" class="marker-popup-full"
             :key="transect.id" @mouseleave="hideFullPopup(transect.id,index)"
             @click="clickPopup(transect.id,index,true)"
             @blur="hideFullPopup(transect.id,index)" @mouseover="clearTimer(index)" @focus="clearTimer(index)">
            <slot name="popupFull" :transect="transect" :index="index"></slot>
        </div>
    </div>
</template>

<script>
    /* eslint no-underscore-dangle: 0 */
    import L from "leaflet";
    import {MapMixin} from "@/mixins/MapMixin";

    export default {
        name: "MapMultiTransects",

        mixins: [MapMixin],

        props: {
            mapId: {
                type: String,
                default: "map",
            },
            height: {
                type: String,
                default: null,
            },
            popup: {
                type: Boolean,
                default: false,
            },
            popupFull: {
                type: Boolean,
                default: false,
            },
            transects: {
                type: Array,
                default: () => [],
            },
            field: {
                type: Array,
                default: () => [],
            },
            openPopupByDefault: {
                type: Boolean,
                default: false,
            },
            openPopupOnHover: {
                type: Boolean,
                default: false,
            },
            openPopupOnClick: {
                type: Boolean,
                default: false,
            },
            changeColorOnHover: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: "red",
            },
            colorOnHover: {
                type: String,
                default: "red",
            },
            zoomControls: {
                type: Boolean,
                default: true,
            },
            isOn: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                markersCoordinates: [],
                redCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='red' /></svg>",
                greyCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='grey' /></svg>",
                lightGreyCircle: "<svg height='10' width='10'><circle cx='5' cy='5' r='5' fill='lightgrey' /></svg>",
                redPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='red' /></svg>",
                greyPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='grey' /></svg>",
                lightGreyPoint: "<svg height='1' width='1'><circle cx='0.5' cy='0.5' r='0.5' fill='lightgrey' /></svg>",
                fieldOnMap: [],
                polandBoundaries: [
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 54.8333333},
                    {longitude: 24.15, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 49},
                    {longitude: 14.116666666666667, latitude: 54.8333333},
                ],
                currentPositionsOnMap: new Map(),
                timer: [],
                polylines: new Map(),
                markers: new Map(),
            };
        },

        emits: ["onClick", "onHover"],

        mounted() {
            this.initCustomIdMap(this.mapId);
            L.Popup.prototype._animateZoom = function (e) {
                if (!this._map) {
                    return;
                }
                const pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center);
                const anchor = this._getAnchor();
                L.DomUtil.setPosition(this._container, pos.add(anchor));
            };
            this.addZoomControls();
            if (this.field !== null && this.field.length > 0) {
                this.makeField();
            } else {
                this.zoomToPoland();
            }
            this.makeTransects();
        },

        methods: {
            mouseoverOrFocusPopup(transectId, index) {
                if (this.popupFull) this.showFullPopup(transectId, index);
            },
            clickPopup(transectId, index, fullIsOpen = false) {
                if (this.popupFull) {
                    this.showFullPopup(transectId, index, true, fullIsOpen);
                    this.hideOtherFullPopups(transectId);
                }
            },
            addZoomControls() {
                if (this.zoomControls) {
                    const zoom = L.control.zoom({
                        position: "bottomright",
                    });
                    zoom.addTo(this.map);
                }
            },
            makeField() {
                for (let i = 0; i < this.field.length; i += 1) {
                    this.fieldOnMap.push([this.field[i].latitude, this.field[i].longitude]);
                }
                const fieldShape = L.polygon(this.fieldOnMap);
                fieldShape.setStyle({fillColor: "transparent", color: "#33795B"});
                fieldShape.addTo(this.map);
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 6);
            },
            zoomToPoland() {
                for (let i = 0; i < this.polandBoundaries.length; i += 1) {
                    this.fieldOnMap.push([this.polandBoundaries[i].latitude, this.polandBoundaries[i].longitude]);
                }
                this.map.fitBounds(this.fieldOnMap);
                this.map.setMinZoom(this.map.getZoom() - 1);
            },
            makeTransects() {
                this.transects.forEach((transect, index) => {
                    if (transect.line?.length > 0) {
                        this.makeTransect(transect, index);
                    }
                });
                this.map.on("click", () => this.hideAllFullPopups());
            },
            makeTransect(transect, index) {
                const polyline = this.makePolyline(transect, index);
                this.makeStartEndCircles(transect, index);
                this.makePopup(transect, index, polyline);
            },
            makePopup(transect, index, polyline) {
                const centerMarker = this.getPointMarker(transect.id + "-center");
                const center = polyline.getCenter();
                const marker = L.marker([center.lat, center.lng], centerMarker).addTo(this.map);
                if (this.openPopupByDefault || transect.openPopup) {
                    const popup = L.popup().setContent(this.$refs.popup[index]);
                    marker.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                }
                this.markers.set(transect.id + "-center", marker);
            },
            makeStartEndCircles(transect, index) {
                const startMarkerConfig = this.getMarker(transect.id + "-start", transect.color, transect.zIndexOffset);
                const endMarkerConfig = this.getMarker(transect.id + "-end", transect.color, transect.zIndexOffset);
                const end = transect.line.length - 1;
                const startMarker = L.marker([transect.line[0].latitude, transect.line[0].longitude],
                                             startMarkerConfig).addTo(this.map);
                startMarker.on("click", () => { this.$emit("onClick", index); });
                startMarker.on("mouseover", () => { this.$emit("onHover", index); });
                startMarker.on("mouseout", () => { this.$emit("onHover", null); });
                const endMarker = L.marker([transect.line[end].latitude, transect.line[end].longitude],
                                           endMarkerConfig).addTo(this.map);
                endMarker.on("click", () => { this.$emit("onClick", index); });
                endMarker.on("mouseover", () => { this.$emit("onHover", index); });
                endMarker.on("mouseout", () => { this.$emit("onHover", null); });
                this.markers.set(transect.id + "-start", startMarker);
                this.markers.set(transect.id + "-end", endMarker);
            },
            makePolyline(transect, index) {
                const latlngs = transect.line.map((line) => [line.latitude, line.longitude]);
                const color = transect.color != null ? transect.color : "red";
                const p = L.polyline(latlngs, {color}).addTo(this.map);
                p.on("click", () => { this.$emit("onClick", index); });
                p.on("mouseover", () => { this.$emit("onHover", index); });
                p.on("mouseout", () => { this.$emit("onHover", null); });
                this.polylines.set(transect.id, p);
                return p;
            },
            updateTransects(transects) {
                transects.forEach((transect, index) => {
                    if (transect.line.length > 0) {
                        this.updateTransect(transect, index);
                    }
                });
            },
            updateTransect(transect, index) {
                this.updatePolyline(transect);
                this.updateStartEndCircles(transect);
                this.updatePopup(transect, index);
            },
            updatePopup(transect, index) {
                const centerMarker = this.markers.get(transect.id + "-center");
                const polyline = this.polylines.get(transect.id);
                const center = polyline.getCenter();
                centerMarker.setLatLng([center.lat, center.lng]);
                if (this.openPopupByDefault || transect.openPopup) {
                    if (!centerMarker.isPopupOpen()) {
                        const popup = L.popup().setContent(this.$refs.popup[index]);
                        centerMarker.bindPopup(popup, {autoClose: false, closeOnClick: false}).openPopup();
                    }
                } else if (centerMarker.isPopupOpen()) {
                    centerMarker.closePopup().unbindPopup();
                }
            },
            updateStartEndCircles(transect) {
                const startMarker = this.markers.get(transect.id + "-start");
                const endMarker = this.markers.get(transect.id + "-end");
                const icon = this.getIcon(transect.color);
                startMarker.setZIndexOffset(transect.zIndexOffset);
                startMarker.setIcon(icon);
                startMarker.setLatLng([transect.line[0].latitude, transect.line[0].longitude]);
                endMarker.setZIndexOffset(transect.zIndexOffset);
                endMarker.setIcon(icon);
                const end = transect.line.length - 1;
                endMarker.setLatLng([transect.line[end].latitude, transect.line[end].longitude]);
            },
            updatePolyline(transect) {
                const polyline = this.polylines.get(transect.id);
                const color = transect.color != null ? transect.color : "red";
                polyline.setStyle({color});
                const latlngs = transect.line.map((line) => [line.latitude, line.longitude]);
                polyline.setLatLngs(latlngs);
            },
            getPointMarker(id) {
                // eslint-disable-next-line
                const icon = L.divIcon({
                    html: this.redPoint,
                    iconSize: [1, 1],
                    iconAnchor: [0.5, 0.5],
                    popupAnchor: [0, 0],
                });
                return {
                    icon,
                    bubblingMouseEvents: false,
                    draggable: this.isOn,
                    id,
                    dontHidePopup: false,
                };
            },
            getMarker(id, color, zIndexOffset = 1) {
                return {
                    icon: this.getIcon(color),
                    bubblingMouseEvents: false,
                    zIndexOffset,
                    draggable: this.isOn,
                    id,
                    dontHidePopup: false,
                };
            },
            getIcon(color) {
                let icon = this.redCircle;
                if (color === "red") icon = this.redCircle;
                else if (color === "grey") icon = this.greyCircle;
                else if (color === "lightGrey") icon = this.lightGreyCircle;
                // eslint-disable-next-line
                return new L.divIcon({
                    html: icon,
                    iconSize: [12, 12],
                    iconAnchor: [5, 7.5],
                    popupAnchor: [1, -34],
                });
            },
            clearTimer(i) {
                if (this.timer[i] !== null) {
                    clearTimeout(this.timer[i]);
                }
            },
            showFullPopup(transectId, i, dontHidePopup = false, fullIsOpen = false) {
                const marker = this.markers.get(transectId + "-center");
                if (!fullIsOpen) {
                    marker.closePopup();
                    marker.setPopupContent(this.$refs.popupFull[i]);
                    marker.openPopup();
                }
                marker.options.dontHidePopup = dontHidePopup;
            },
            hideFullPopup(transectId, i) {
                const marker = this.markers.get(transectId + "-center");
                this.timer[i] = setTimeout(() => {
                    if (!marker.options.dontHidePopup) marker.setPopupContent(this.$refs.popup[i]);
                }, 200);
            },
            hideOtherFullPopups(transectId) {
                this.transects.forEach((t, i) => {
                    if (t.id !== transectId) {
                        const marker = this.markers.get(t.id + "-center");
                        marker.setPopupContent(this.$refs.popup[i]);
                        marker.options.dontHidePopup = false;
                    }
                });
            },
            hideAllFullPopups() {
                this.transects.forEach((t, i) => {
                    const marker = this.markers.get(t.id + "-center");
                    marker.setPopupContent(this.$refs.popup[i]);
                    marker.options.dontHidePopup = false;
                });
            },
            moveMarkerToNewPoint(positionId, coordinates) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === positionId) {
                            layer.setLatLng([coordinates.latitude, coordinates.longitude]);
                        }
                    }
                });
            },
            removeMarker(positionId) {
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        if (layer.options.id === positionId) {
                            layer.removeFrom(this.map);
                        }
                    }
                });
                this.markers.delete(positionId);
            },
        },

        watch: {
            transects: {
                handler(value) { this.updateTransects(value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
@import "../assets/scss/leaflet-map";
@import "../../assets/theme/mytheme/variables";
.map-multi-transects .leaflet-popup .leaflet-popup-content {
    .marker-popup {
        &-header {
            padding: 5px;
        }
        &-content {
            padding: 5px;
        }
    }
    .marker-popup-full {
        &-header {
            padding: 15px;
        }
        &-content {
            padding: 15px;
        }
    }
}
</style>
