<template>
    <Dialog header="Dane siedliskowe" v-model:visible="internalVisible" :closable="true" modal
            :draggable="false" style="width: 99%;">
        <template #header>
        </template>
        <HabitatDataDialogTable :columns="tableColumns" :programCode="programCode"
                                :gHistoryId="gHistoryId" />
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import HabitatDataDialogTable from "./HabitatDataDialogTable.vue";

    export default {
        name: "HabitatDataDialog",

        components: {
            Dialog,
            HabitatDataDialogTable,
        },

        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            gHistoryId: null,
            programCode: null,
        },

        data() {
            return {
                tableColumns: [],
                columnField: null,
                columnControlDate: null,
                columnHab1Lev1: null,
                columnHab1Lev2: null,
                columnHab1Lev3_1: null,
                columnHab1Lev3_2: null,
                columnHab1Lev3_3: null,
                columnHab2Lev1: null,
                columnHab2Lev2: null,
                columnHab2Lev3_1: null,
                columnHab2Lev3_2: null,
                columnHab2Lev3_3: null,
                columnUtilization: null,
                columnDifferentUtilization: null,
                columnWhen: null,
                columnRut: null,
                columnClumps: null,
                columnPlantHeight: null,
                columnTreeBushesArea: null,
                columnTreeType: null,
                columnBushHeight: null,
                columnCane: null,
                columnWater: null,
                columnNestPlace: null,
                columnPlantLitter: null,
                columnAccepted: null,
                columnHabitatType: null,
                columnHabitatType2: null,
                columnHabitatTypeList: null,
                columnThermalModernization: null,
                columnSwiftBoxes: null,
                columnCrops: null,
                columnBuildingDistance: null,
                columnWaterDistance: null,
                columnSlopeHeight: null,
                columnHabitatNotes: null,
            };
        },
        beforeMount() {
            this.assignColumnProps();
            /* eslint-disable */
            if (this.programCode === "MPPL" || this.programCode === "MPM") {
                this.tableColumns = [this.columnField, this.columnControlDate, this.columnHab1Lev1, this.columnHab1Lev2, this.columnHab1Lev3_1, this.columnHab1Lev3_2, this.columnHab1Lev3_3, this.columnHab2Lev1, this.columnHab2Lev2, this.columnHab2Lev3_1, this.columnHab2Lev3_2, this.columnHab2Lev3_3];
            } else if (this.programCode === "MWOT") {
                this.tableColumns = [this.columnField, this.columnControlDate, this.columnUtilization, this.columnDifferentUtilization, this.columnClumps, this.columnTreeType, this.columnWater, this.columnWhen, this.columnPlantHeight, this.columnBushHeight, this.columnPlantLitter, this.columnRut, this.columnTreeBushesArea, this.columnCane, this.columnNestPlace];
            } else if (this.programCode === "MPPM") {
                this.tableColumns = [this.columnField, this.columnControlDate, this.columnAccepted,
                this.columnHabitatType, this.columnHabitatType2, this.columnThermalModernization, this.columnSwiftBoxes];
            } else if (this.programCode === "MZO") {
                this.tableColumns = [this.columnField, this.columnControlDate, this.columnAccepted, this.columnHabitatType, this.columnCrops, this.columnBuildingDistance, this.columnWaterDistance, this.columnSlopeHeight];
            } else if (this.programCode === "MSL") {
                this.tableColumns = [this.columnField, this.columnControlDate, this.columnAccepted, this.columnHabitatTypeList, this.columnHabitatNotes];
            } else {
                this.tableColumns = [];
            }
        },

        methods: {
            assignColumnProps() {
                this.columnField = {header: "Powierzchnia", field: "fieldName", filterField: "field",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyLink: true,
                    linkTo: (data) => this.getFieldLink(data), bodyContent: (data) => data.field.name,
                    filterItemsKey: "fields", ...this.multiSelectProps, filterOtherAttrs: {}, filterType: "dictionary",
                    bodyClass: () => ""};
                this.columnControlDate = {header: "Data kontroli", field: "controlDate", bodyContent: (data) => data.controlDate, bodyClass: () => "", editorType: "calendar", columnWidth: 1};
                this.columnHab1Lev1 = {header: "hab1Lev1", field: "hab1Lev1", bodyContent: (data) => data.hab1Lev1, bodyClass: () => "", editorType: "inputText", columnWidth: 1};
                this.columnHab1Lev2 = this.getNumberColumn("hab1Lev2", "hab1Lev2", 1);
                this.columnHab1Lev3_1 = this.getNumberColumn("hab1Lev3_1", "hab1Lev3_1", 1);
                this.columnHab1Lev3_2 = this.getNumberColumn("hab1Lev3_2", "hab1Lev3_2", 1);
                this.columnHab1Lev3_3 = this.getNumberColumn("hab1Lev3_3", "hab1Lev3_3", 1);
                this.columnHab2Lev1 = {header: "hab2Lev1", field: "hab2Lev1", bodyContent: (data) => data.hab2Lev1, bodyClass: () => "", editorType: "inputText", columnWidth: 1};
                this.columnHab2Lev2 = this.getNumberColumn("hab2Lev2", "hab2Lev2", 1);
                this.columnHab2Lev3_1 = this.getNumberColumn("hab2Lev3_1", "hab2Lev3_1", 1);
                this.columnHab2Lev3_2 = this.getNumberColumn("hab2Lev3_2", "hab2Lev3_2", 1);
                this.columnHab2Lev3_3 = this.getNumberColumn("hab2Lev3_3", "hab2Lev3_3", 1);
                this.columnUtilization = this.getTooltipColumn("Użytkowanie", "utilization");
                this.columnDifferentUtilization = {header: "Użytkowanie inne", field: "differentUtilization", bodyContent: (data) => data.differentUtilization, bodyClass: () => "", editorType: "inputText", columnWidth: 1};
                this.columnWhen = this.getTooltipColumn("Kiedy", "when");
                this.columnRut = this.getTooltipColumn("Koleiny", "rut");
                this.columnClumps = this.getTooltipColumn("Kępy", "clumps");
                this.columnPlantHeight = this.getTooltipColumn("Wysokość roślinności", "plantHeight");
                this.columnTreeBushesArea = this.getTooltipColumn("Drzewa i krzewy", "treeBushesArea");
                this.columnTreeType = this.getTooltipColumn("Gatunek", "treeType");
                this.columnBushHeight = this.getNumberColumn("Wysokość zakrzewień", "bushHeight", 2);
                this.columnCane = this.getTooltipColumn("Trzcina", "cane");
                this.columnWater = this.getTooltipColumn("Woda", "water");
                this.columnNestPlace = this.getTooltipColumn("Miejsce na gniazdo", "nestPlace");
                this.columnPlantLitter = this.getTooltipColumn("Ściółka", "plantLitter");
                this.columnHabitatType = this.getTooltipColumn("Siedlisko", "habitatType");
                this.columnHabitatType2 = this.getTooltipColumn("Siedlisko2", "habitatType2", "habitatType");
                this.columnHabitatTypeList = {header: "Siedlisko", field: "habitatType", filterField: "habitatType",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyContent: (data) => data.habitatType, filterItemsKey: "habitatType", ...this.multiSelectProps,
                    filterOtherAttrs: {}, filterType: "dictionary", isList: true, columnWidth: 2, editorType: "multiselect", bodyClass: () => "", isList: true};
                this.columnThermalModernization = this.getTooltipColumn("Termomodernizacja", "thermalModernization");
                this.columnSwiftBoxes = this.getBooleanColumn("Budki dla jerzyków", "swiftBoxes");
                this.columnAccepted = this.getBooleanColumn("Zaakceptowano", "accepted");
                this.columnCrops = {header: "Uprawy w promieniu 500m", field: "crops", filterField: "crops",
                    showFilterMatchModes: false, filterMenuClass: "w-16rem", sortable: true, bodyContent: (data) => data.crops, filterItemsKey: "crops", ...this.multiSelectProps,
                    filterOtherAttrs: {}, filterType: "dictionary", isList: true, columnWidth: 2, editorType: "multiselect", bodyClass: () => "", isList: true};
                this.columnBuildingDistance = this.getNumberColumn("Przybliżona odległość do najbliższych zabudowań (m)","buildingDistance", 3, true);
                this.columnWaterDistance = this.getNumberColumn("Przybliżona odległość do najbliższej doliny rzecznej lub zbiornika wodnego (m)","waterDistance", 4, true);
                this.columnSlopeHeight = this.getNumberColumn("Szacunkowa wysokość skarpy (m)","slopeHeight", 2, true);
                this.columnHabitatNotes = this.getTextColumn("Uwagi", "habitatNotes");
            },
            getNumberColumn(header, field, width, longLabel = false) {
                return {header: header, field: field, bodyClass: () => "",
                    bodyContent: (data) => data[field], editorType: "inputNumber", columnWidth: width, longLabel: longLabel};
            },
            getTextColumn(header, field) {
                return {header: header, field: field, filterField: null, showFilterMatchModes: false,
                    filterMenuClass: "", sortable: false, bodyLink: false,
                    bodyClass: () => "", bodyTooltip: false, bodyContent: (data) => data[field], filterOtherAttrs: {}, filterType: "inputText"};
            },
            getBooleanColumn(header, field) {
                return {header: header, field: field, bodyLink: false, bodyClass: (data) => data[field] ? "true-style" : "false-style",
                    bodyTooltip: false, bodyContent: (data) => this.computedBoolean(data[field]), columnWidth: 1, editorType: "checkbox"};
            },
            getTooltipColumn(header, field, editorItemsKey = field) {
                return {header: header, field: field, bodyLink: false, bodyClass: () => "",
                    bodyTooltip: true, bodyTooltipContent: (data) => data[field].label,
                    bodyContent: (data) => data[field].code, ...this.multiSelectProps,
                    editorType: "selectOne", editorEmptyValue: false, editorItems: editorItemsKey, editorItemLabel: "label"};
            },

            computedBoolean(value) {
                if (value) return "Tak";
                return "Nie";
            },
        },

        computed: {
            internalVisible: {
                get() { return this.visible; },
                set(value) { this.$emit("update:visible", value); },
            },
        },
    };
</script>

<style lang="scss">
    @import "../../assets/theme/mytheme/variables";
    @import "../assets/scss/dialogs.scss";
</style>
