<template>
    <div class="flex flex-column">
        <CustomCheckbox label="Brak gniazda" v-model="position.noNest" :disabled="!editing" name="noNest" />
        <div v-if="!position.noNest" class="flex gap-7 nest-data-container">
            <div class="left-panel">
                <div class="observation-map" style="width: 400px;">
                    <MapSingleMarker mapId="nestMap" height="350px" v-model="nestCoordinates"
                                     :zoomControls="false" :field="field" @emitCoor="setLatLng"
                                     @isMarkerInside="isMarkerInside" />
                </div>
            </div>
            <div class="right-panel flex flex-column">
                <div class="coordinates-box flex gap-5">
                    <CustomInputNumber class="w-15rem input-width" label="Koordynaty N" v-model="position.latitude"
                                       :maxDigits="6" :minDigits="6" :disabled="!editing" name="nestLat"
                                       required :showErrors="showErrorMessages"
                                       :customErrorMessage="nestOutsideArea()" />
                    <CustomInputNumber class="w-15rem input-width" label="Koordynaty E" v-model="position.longitude"
                                       :maxDigits="6" :minDigits="6" :disabled="!editing" name="nestLng"
                                       required :showErrors="showErrorMessages"
                                       :customErrorMessage="nestOutsideArea()" />
                </div>
                <div class="info-box flex gap-5">
                    <CustomSelectOne class="w-15rem input-width" label="Rodzaj gniazda" :items="dicts.nestType" required
                                     v-model="position.nestType" :disabled="!editing" name="nestType"
                                     :showErrors="showErrorMessages" />
                    <div class="dummy input-width"></div>
                </div>
                <div class="info-box flex gap-5">
                    <CustomSelectOne class="w-15rem input-width" label="Nazwa drzewa" :items="dicts.treeType" required
                                     v-model="position.treeType" :disabled="!editing" name="treeType"
                                     :showErrors="showErrorMessages" />
                    <CustomSelectOne class="w-15rem input-width" label="Kod drzewa" itemLabel="code" :items="dicts.treeType"
                                     v-model="position.treeType" :disabled="!editing" name="treeType"
                                     required :showErrors="showErrorMessages" />
                </div>
                <CustomInputTextArea label="Dodatkowe informacje" :rows="7" :disabled="!editing"
                                     v-model="position.additionalInfo" name="nestAdditionalInfo" />
            </div>
        </div>
    </div>
</template>

<script>
    import {MapMixin} from "@/mixins/MapMixin";
    import CustomCheckbox from "@/components/form/CustomCheckbox.vue";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber.vue";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea.vue";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "NestDataMPS",

        components: {
            CustomSelectOne,
            CustomInputTextArea,
            CustomInputNumber,
            MapSingleMarker,
            CustomCheckbox,
        },

        mixins: [MapMixin],

        props: {
            modelValue: null,
            positions: null,
            field: null,
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        data() {
            return {
                nestCoordinates: [],
                markerInside: true,
            };
        },

        beforeMount() {
            this.nestCoordinates = [this.position?.latitude, this.position?.longitude];
        },

        methods: {
            setLatLng(coordinates) {
                this.position.latitude = coordinates.lat;
                this.position.longitude = coordinates.lng;
            },
            isMarkerInside(value) {
                this.markerInside = value;
            },
            nestOutsideArea() {
                if (!this.markerInside
                    && this.position.latitude != null
                    && this.position.longitude != null) {
                    return "Gniazdo poza obszarem";
                }
                return "";
            },
        },

        computed: {
            position: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            position: {
                handler(value) {
                    this.nestCoordinates = [value.latitude, value.longitude];
                    this.$emit("update:modelValue", value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
@media screen and (max-width: 980px) {
    .nest-data-container {
        flex-direction: column-reverse;
        gap: 1.5rem !important;
        .input-width {
            width: 50% !important;
        }
        .observation-map {
            width: 100% !important;
        }
    }
}
@media screen and (max-width: 576px) {
    .coordinates-box, .info-box {
        flex-direction: column;
        gap: 0.5rem !important;
    }
    .nest-data-container .input-width {
        width: 100% !important;
    }
}
</style>
