<template>
    <FormObservationsTemplate ref="observationsTemplate" v-model:additionalInformation="internalForm">
        <template v-slot:controlData>
            <ControlTypeAndDates v-model="internalForm" />
            <div class="flex gap-2 flex-direction-column">
                <CustomInputNumber label="Zlodzenie (%)" name="icing" v-model="internalForm.icing" class="w-4 max-width"
                                   :max="100" :showErrors="showErrorMessages" required :disabled="!editing"/>
                <CustomInputNumber label="Kompletność (%)" name="completeness" :disabled="!editing"
                                   v-model="internalForm.completeness" class="w-4 max-width"
                                   :max="100" :showErrors="showErrorMessages" required />
                <CustomSelectOne label="Metoda liczenia" name="controlMethod" :disabled="!editing"
                                 v-model="internalForm.controlMethod" class="w-4 max-width" :items="dicts.controlMethod"
                                 :showErrors="showErrorMessages" required />
            </div>
        </template>
        <template v-slot:birdsData>
            <FormBirdsMZPW v-model:noBirds="internalForm.noBirds" v-model:birds="internalForm.birds" />
        </template>
    </FormObservationsTemplate>
</template>

<script>
    import ControlTypeAndDates from "@/components/formSharedComponents/ControlTypeAndDates.vue";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import FormBirdsMZPW from "@/views/formMZPW/components/FormBirdsMZPW.vue";
    import FormObservationsTemplate from "@/views/form/FormObservationsTemplate.vue";

    export default {
        name: "FormMZPWObservations",

        components: {
            FormObservationsTemplate,
            FormBirdsMZPW,
            ControlTypeAndDates,
            CustomInputNumber,
            CustomSelectOne,
        },

        props: {
            form: {
                type: Object,
                required: true,
            },
        },

        inject: ["showErrorMessages", "dicts", "editing"],

        computed: {
            internalForm: {
                get() { return this.form; },
                set(value) { this.$emit("update:form", value); },
            },
        },

        watch: {
            internalForm: {
                handler(value) {
                    this.$emit("update:form", value);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 700px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
