<template>
    <div class="w-full flex flex-column">
        <div v-if="mng" class="w-full flex gap-6 mb-4">
            <CustomInfoBox class="w-6"
                           text="Np. zmiany siedliskowe i ewentualne przyczyny porzucenia noclegowiska,
                                zaobserwowane zagrożenia dla ptaków." />
            <div class="w-6"></div>
        </div>
        <div class="w-full flex gap-6 align-items-end addinf-textareas-container">
            <CustomInputTextArea v-if="mzpm" label="Uwagi kapitana" class="w-full mb-0" name="captainNotes"
                                 :disabled="!editing || !isCaptain" v-model="internalCaptainNotes" />
            <CustomInputTextArea v-else label="Uwagi" class="w-full mb-0" name="notes" v-model="control.notes"
                                 :disabled="!editing || !isCaptain" />
            <CustomInputTextArea label="Dodatkowi obserwatorzy" class="w-full mb-0" name="additionalObservers"
                                 v-model="control.additionalObservers" :disabled="!editing || !isCaptain" />
        </div>
    </div>
</template>

<script>
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import CustomInfoBox from "@/components/form/CustomInfoBox";

    export default {
        name: "AdditionalInformation",

        components: {
            CustomInputTextArea,
            CustomInfoBox,
        },

        props: {
            modelValue: null,
            mng: {
                type: Boolean,
                default: false,
            },
            mzpm: {
                type: Boolean,
                default: false,
            },
            isCaptain: {
                type: Boolean,
                default: true,
            },
            captainNotes: null,
        },

        inject: ["editing"],

        computed: {
            control: {
                get() {
                    return this.modelValue;
                },
                set(value) { this.$emit("update:modelValue", value); },
            },
            internalCaptainNotes: {
                get() {
                    return this.captainNotes;
                },
                set(value) { this.$emit("update:captainNotes", value); },
            },
        },

        watch: {
            control: {
                handler(v) {
                    this.$emit("update:modelValue", v);
                },
                deep: true,
            },
        },
    };
</script>

<style lang="scss">
@media screen and (max-width: 700px) {
    .addinf-textareas-container {
        flex-direction: column;
        gap: 1rem !important;
    }
}
</style>
