<template>
    <HabitatDataTemplate :habitatData="habitatData" v-model:habitatDataToChange="localHabitatDataToChange"
                         :emptyHabitatData="EmptyStructures.getEmptyMNZHabitatData()">
        <template v-slot:data="slotProps">
            <div class="flex column-gap-3 flex-direction-column">
                <CustomSelectOne label="Siedlisko w miejscu nocowania żurawi" name="habitatType"
                                 v-model="slotProps.habitatData.habitatType" :disabled="slotProps.disabled"
                                 :items="dicts.habitatType" :filter="false"
                                 class="w-6 max-width" required :showErrors="showErrorMessages"
                                 @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)" longLabelSelect/>
                <CustomMultiSelect label="Jednostka roślinności w miejscu nocowania" name="dominantVegetation"
                                   v-model="slotProps.habitatData.dominantVegetation" :disabled="slotProps.disabled"
                                   :items="dicts.dominantVegetation" :filter="false" class="w-6 max-width"
                                   @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)" longLabelSelect/>
            </div>
            <CustomInfoBox text="Np. zmiany siedliskowe i ewentualne przyczyny porzucenia noclegowiska, zaobserwowane zagrożenia dla ptaków."
                           class="mb-3 auto-height" />
            <CustomInputTextArea label="Uwagi" name="habitatNotes" v-model="slotProps.habitatData.habitatNotes"
                                 :disabled="slotProps.disabled"
                                 @update:modelValue="$emit('update:habitatDataToChange',slotProps.habitatData)"/>
        </template>
    </HabitatDataTemplate>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect.vue";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea.vue";
    import { EmptyStructures } from "@/utils/EmptyStructures";
    import CustomInfoBox from "@/components/form/CustomInfoBox";
    import HabitatDataTemplate from "@/views/form/HabitatDataTemplate.vue";

    export default {
        name: "HabitatDataMNZ",

        components: {
            HabitatDataTemplate,
            CustomSelectOne,
            CustomMultiSelect,
            CustomInputTextArea,
            CustomInfoBox,
        },

        props: {
            habitatData: {
                type: Object,
            },
            habitatDataToChange: {
                type: Object,
            },
        },

        inject: ["dicts", "showErrorMessages"],

        emits: ["update:habitatDataToChange"],

        computed: {
            EmptyStructures() {
                return EmptyStructures;
            },
            localHabitatDataToChange: {
                get() { return this.habitatDataToChange; },
                set(value) { this.$emit("update:habitatDataToChange", value); },
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 750px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
        .auto-height {
            height: auto;
        }
    }
</style>
