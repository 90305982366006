<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :someGeobjectWasAudited="someGeobjectWasAudited"
                      :validationCorrect="validationCorrect" @goTo2Step="validateFirstStep">
        <template v-slot:formMap>
            <FormPositions v-model="form.positions" :field="form.field" @reloadFormData="getFormData"/>
        </template>
        <template v-slot:formObservations>
            <FormMGRObservations :field="form.field.shape" :allPositions="form.positions"
                                 ref="observationsView" v-model:form="form" v-model:step="step"/>
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import FormPositions from "../../components/formSharedComponents/FormPositions.vue";
    import FormMGRObservations from "./components/FormMGRObservations.vue";
    import {DateUtils} from "@/utils/DateUtils";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import {getForm, getList as getDict} from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";

    export default {
        name: "FormMGRView",

        components: {
            FormViewTemplate,
            FormPositions,
            FormMGRObservations,
        },

        data() {
            return {
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
                programCode: "MOP",
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            const pathArray = this.$route.path.split("/");
            if (pathArray.length > 2) {
                this.programCode = pathArray[2];
            }
            this.getFormData();
        },

        methods: {
            getFormData() {
                console.log(this.programCode);
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        ValidateUtils.flattenData(this.form.positions, "position");
                        for (let i = 0; i < this.form.positions.length; i += 1) {
                            if (this.form.positions[i].control1 == null) this.form.positions[i].control1 = {};
                            if (this.form.positions[i].control2 == null) this.form.positions[i].control2 = {};
                        }
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((err) => {
                        if (err?.response?.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                        console.log(err);
                        this.loaded = false;
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, "positions");
                if (!this.form.noFormControl) {
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        const p = this.form.positions[i];
                        p.control1.controlDate = DateUtils.formatFormDate(p.control1.controlDate);
                        p.control2.controlDate = DateUtils.formatFormDate(p.control2.controlDate);
                        request.positions.push(p);
                    }
                }
                return request;
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            validateControls(i) {
                const position = this.form.positions[i];
                let isCorrect = true;
                const c1 = position.control1;
                if (c1.noControl !== true
                    && (c1.controlDate == null || c1.controlDate === "" || c1.nestingCriteria == null
                        || (c1.activityDetected && c1.humanActivity == null))) {
                    c1.error = true;
                    isCorrect = false;
                }
                isCorrect = ValidateUtils.validateIsDateInRange(c1.controlDate, this.dicts.controlType[0].controlStart, this.dicts.controlType[0].controlEnd) && isCorrect;
                const c2 = position.control2;
                let isC2Correct = true;
                if (c2.noControl !== true) {
                    if (c2.controlDate == null || c2.controlDate === "" || c2.nestingCriteria == null
                        || (c2.nestingCriteria.key !== "MGR1_BRAK" && c2.breedingResult == null)
                        || (c2.activityDetected && c2.humanActivity == null)) isC2Correct = false;
                    else if (c2.breedingResult != null && c2.breedingResult.key === "SUCCESS"
                        && (c2.controlMethod == null || c2.nestlings == null
                            || c2.nestlings === "")) isC2Correct = false;
                    else if (c2.breedingResult != null && c2.breedingResult.key === "LOSSES"
                        && c2.lossesStage == null) isC2Correct = false;
                }
                isC2Correct = ValidateUtils.validateIsDateInRange(c2.controlDate, this.dicts.controlType[1].controlStart, this.dicts.controlType[1].controlEnd) && isC2Correct;
                if (!isC2Correct) {
                    c2.error = true;
                    isCorrect = false;
                }
                return isCorrect;
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    for (let i = 0; i < this.form.positions.length; i += 1) {
                        const position = this.form.positions[i];
                        if (position.error) delete position.error; // remove previous checks
                        if (position.control1.error) delete position.control1.error;
                        if (position.control2.error) delete position.control2.error;
                        if (position.notAudited === true) {
                            if (position.notAuditedReason === null || position.notAuditedReason === "") {
                                this.addErrorToast(`${position.name}: stanowisko nieskontrolowane, podaj powód.`);
                                isCorrect = false;
                            }
                        } else {
                            isCorrect = this.validateControls(i) && isCorrect;
                        }
                    }
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
                console.log(this.request);
            },
            validateFirstStep() {
                const errors = ValidateUtils.validateNotAudited(this.form.positions, "Stanowisko");
                this.addErrorToasts(errors);
                return errors.length === 0;
            },
            addErrorToasts(errors) {
                errors.forEach((message) => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Błąd",
                        detail: message,
                        life: 5000,
                    });
                });
            },
        },

        computed: {
            someGeobjectWasAudited() {
                return ValidateUtils.someGeobjectWasAudited(this.form.positions, this.form.noFormControl);
            },
        },
    };
</script>

<style lang="scss">
</style>
