<template>
    <FormViewTemplate v-if="loaded" v-model:form="form" v-model:editing="editing" v-model:step="step"
                      v-model:showErrorMessages="showErrorMessages" @validate="validateData"
                      :request="request" :validationCorrect="validationCorrect">
        <template v-slot:formMap>
            <CustomInfoBox text="UWAGA! Aby wprowadzić obserwacje na innym stanowisku powierzchniowym wróć do strony głównej i wejdź do odpowiedniego formularza."
                           class="mx-3 auto-height" />
            <div class="form-map mt-3 mb-5">
                <MapSingleMarker mapId="mainMap" :field="form.field.superField.shape" :subfield="form.field"
                                 :otherSubfields="form.field.superField.otherSubfields" :isOn="false" subfieldPopup>
                    <template v-slot:subfieldPopup="slotProps">
                        <div class="marker-popup-header">
                            {{ slotProps.subfield.name }}
                        </div>
                    </template>
                </MapSingleMarker>
            </div>
        </template>
        <template v-slot:formObservations>
            <FormMPOObservations v-model:form="form" ref="observationsView" v-model:step="step" />
        </template>
    </FormViewTemplate>
</template>

<script>
    import {computed} from "vue";
    import {
        getForm,
        getList as getDict,
    } from "@/swagger/vue-api-client";
    import {ProgramEditionState} from "@/utils/ProgramEditionState";
    import {ValidateUtils} from "@/utils/ValidateUtils";
    import FormViewTemplate from "@/views/form/FormViewTemplate.vue";
    import MapSingleMarker from "@/components/map/MapSingleMarker.vue";
    import FormMPOObservations from "@/views/formMPO/components/FormMPOObservations.vue";
    import CustomInfoBox from "@/components/form/CustomInfoBox.vue";

    export default {
        name: "FormMPOView",

        components: {
            FormMPOObservations,
            MapSingleMarker,
            FormViewTemplate,
            CustomInfoBox,
        },

        data() {
            return {
                programCode: "MPO",
                form: {},
                loaded: false,
                editing: false,
                showErrorMessages: false,
                dicts: {},
                request: {},
                validationCorrect: false,
                step: 1,
            };
        },

        provide() {
            return {
                dicts: computed(() => this.dicts),
                editing: computed(() => this.editing),
                showErrorMessages: computed(() => this.showErrorMessages),
                programCode: computed(() => this.programCode),
                controlTimeFrame: computed(() => this.form.control),
                programEditionId: computed(() => this.form.programEditionId),
            };
        },

        beforeMount() {
            if (this.$route.query.step != null && this.$route.query.step === "2") this.step = 2;
            this.getFormData();
        },

        methods: {
            getFormData() {
                getForm({program: this.programCode, formId: this.$route.params.id})
                    .then((response) => {
                        this.form = response.data;
                        console.log(this.form);
                        this.editing = this.form.editable && ProgramEditionState.isEditingByDefault(this.form.state);
                        return getDict({programCode: this.programCode, formId: this.$route.params.id});
                    })
                    .then((response) => {
                        this.dicts = response.data;
                        this.form.control1?.birds?.forEach((b) => {
                            this.dicts.nestingCriteria.forEach((nC) => {
                                if (b.nestingCriteria?.code === nC.code && nC.info) {
                                    b.nestingCriteria.info = nC.info;
                                }
                            });
                        });
                        this.form.control2?.birds?.forEach((b) => {
                            this.dicts.nestingCriteria.forEach((nC) => {
                                if (b.nestingCriteria?.code === nC.code && nC.info) {
                                    b.nestingCriteria.info = nC.info;
                                }
                            });
                        });
                        this.dicts.habitatType.forEach((hT) => {
                            if (hT.info && this.form.habitatType?.code === hT.code) {
                                this.form.habitatType.info = hT.info;
                            }
                        });
                        console.log(this.dicts);
                        this.loaded = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loaded = false;
                    });
            },
            getFormRequest() {
                const request = ValidateUtils.getFormRequest(this.form, this.programCode, null, true);
                if (!this.form.noFormControl) {
                    for (let i = 1; i <= 2; i += 1) {
                        ValidateUtils.formatControlDateStartTimeAndEndTime(this.form[`control${i}`]);
                    }
                    ValidateUtils.copyFieldValues(this.form, request, ["habitatType", "otherHabitat", "vegetation", "otherVegetation", "control1", "control2"]);
                }
                console.log(request);
                return request;
            },
            addErrorToast(detailMessage) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: detailMessage,
                    life: 5000,
                });
            },
            validateControl(control, i) {
                let isCorrect = true;
                if (control.noControl !== true) {
                    isCorrect = ValidateUtils.validateNotEmpty(control, ["controlDate", "startTime", "endTime"]) && isCorrect;
                    isCorrect = ValidateUtils.validateIsDateInRange(control.controlDate, this.dicts.controlType[i].controlStart, this.dicts.controlType[i].controlEnd) && isCorrect;
                }
                if (control.noControl) {
                    isCorrect = ValidateUtils.validateNotEmpty(control, ["noControlReason"]) && isCorrect;
                }
                if (control.noWater !== true) {
                    isCorrect = ValidateUtils.validateBirds(control.birds, control.noBirds, ["nestingCriteria", "count"]) && isCorrect;
                }
                if (control.noWater) {
                    isCorrect = ValidateUtils.validateBirds(control.birds, control.noWater, ["nestingCriteria", "count"]) && isCorrect;
                }
                return isCorrect;
            },
            validateData() {
                let isCorrect = ValidateUtils.validateNoFormControl(this.form);
                if (isCorrect && !this.form.noFormControl) {
                    isCorrect = ValidateUtils.validateNotEmpty(this.form, ["habitatType", "vegetation"]) && isCorrect;
                    if (this.form.habitatType?.code === "INNE") {
                        isCorrect = ValidateUtils.validateNotEmpty(this.form, ["habitatType", "vegetation", "otherHabitat"]) && isCorrect;
                    }
                    if (this.form.vegetation && this.form.vegetation.length > 0 && this.form.vegetation.some((v) => v.code === "INN")) {
                        isCorrect = ValidateUtils.validateNotEmpty(this.form, ["habitatType", "vegetation", "otherVegetation"]) && isCorrect;
                    }
                    isCorrect = this.validateControl(this.form.control1, 0) && isCorrect;
                    isCorrect = this.validateControl(this.form.control2, 1) && isCorrect;
                }
                this.validationCorrect = isCorrect;
                this.request = this.getFormRequest();
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 650px) {
        .auto-height {
            height: auto;
        }
    }
</style>
