<template>
    <div v-if="!loaded" class="flex flex-wrap tiles-container">
        <SingleTile v-for="t in tiles" :key="t.id" :style="`background-color: ${t.bgColor};`"
                    :text="t.text" :count="t.count" />
    </div>
</template>

<script>
    import SingleTile from "./SingleTile.vue";
    import {
        getStatistics,
    } from "@/swagger/vue-api-client";

    export default {
        name: "InfoTiles",

        components: {
            SingleTile,
        },

        data() {
            return {
                tiles: [
                    {
                        id: 0,
                        text: "Formularze do uzupełnienia",
                        code: "notCreated",
                        bgColor: "#d5233f45",
                        count: 0,
                    },
                    {
                        id: 1,
                        text: "Formularze w wersji roboczej",
                        code: "drafts",
                        bgColor: "var(--surface-0)",
                        count: 0,
                    },
                    {
                        id: 2,
                        text: "Formularze w weryfikacji",
                        code: "verification",
                        bgColor: "#05683945",
                        count: 0,
                    },
                    {
                        id: 3,
                        text: "Formularze do poprawy",
                        code: "returned",
                        bgColor: "#d5233f95",
                        count: 0,
                    },
                    {
                        id: 4,
                        text: "Zaakceptowane formularze",
                        code: "approved",
                        bgColor: "#05683995",
                        count: 0,
                    },
                ],
                loaded: false,
            };
        },

        beforeMount() {
            this.loaded = true;
            getStatistics().then((response) => {
                this.tiles.forEach((t) => {
                    t.count = response.data[t.code];
                    this.loaded = false;
                });
            });
        },
    };
</script>

<style lang="scss">
    .tiles-container {
        justify-content: space-between;
        @media screen and (max-width: 800px) {
            justify-content: center;
            gap: 1rem;
        }
    }
</style>
