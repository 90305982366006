<template>
    <div class="flex column-gap-2 flex-direction-column">
        <CustomSelectOne label="Zachmurzenie" :items="dicts.clouds" v-model="control.clouds" class="flex-1 w-0 max-width"
                         :disabled="!editing" name="clouds" :showErrors="showErrorMessages" required />
        <CustomSelectOne label="Wiatr" :items="dicts.wind" v-model="control.wind" class="flex-1 w-0 max-width"
                         :disabled="!editing" name="wind" :showErrors="showErrorMessages" required />
        <CustomSelectOne v-if="!hideRain" label="Deszcz" :items="dicts.rain" v-model="control.rain" class="flex-1 w-0 max-width"
                         :disabled="!editing" name="rain" :showErrors="showErrorMessages" required />
        <CustomSelectOne v-if="!hideVisibility" label="Widoczność" :items="dicts.visibility"
                         v-model="control.visibility" class="flex-1 w-0 max-width" :disabled="!editing"
                         name="visibility" :showErrors="showErrorMessages" required />
        <CustomSelectOne v-if="!hideAudibility" label="Słyszalność" :items="dicts.audibility"
                         v-model="control.audibility" class="flex-1 w-0 max-width" name="audibility" :disabled="!editing"
                         :showErrors="showErrorMessages" required />
    </div>
</template>

<script>
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne.vue";

    export default {
        name: "ControlWeather",

        components: {
            CustomSelectOne,
        },

        props: {
            modelValue: {
                type: Object,
            },
            hideVisibility: {
                type: Boolean,
                default: false,
            },
            hideAudibility: {
                type: Boolean,
                default: false,
            },
            hideRain: {
                type: Boolean,
                default: false,
            },
        },

        inject: ["editing", "dicts", "showErrorMessages"],

        emits: ["update:modelValue"],

        computed: {
            control: {
                get() { return this.modelValue; },
                set(value) { this.$emit("update:modelValue", value); },
            },
        },

        watch: {
            control: {
                handler(value) { this.$emit("update:modelValue", value); },
                deep: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 700px) {
        .flex-direction-column {
            flex-direction: column;
            .max-width {
                width: 100% !important;
            }
        }
    }
</style>
